import { CatalogDiscount, CatalogDiscountType } from "../../models/catalog-discount";
import { CatalogDiscountCode } from "../../models/catalog-discount-code";
import { CatalogItem } from "../../models/catalog-item";
import { CatalogMenu } from "../../models/catalog-menu";
import { CatalogPricingRule, CatalogPricingRuleExcludeStrategyConstant, CatalogPricingRuleTriggerType } from "../../models/catalog-pricing-rule";
import { Money } from "../../models/money";
import { Order, OrderFulfillmentType, OrderLineItemDiscount, OrderLineItemDiscountScopeConstants, OrderLineItemVoucher } from "../../models/order";
import { OrderLineItem, OrderLineItemAppliedDiscount } from "../../models/order-line-item";
import { CodeUtils } from "../../utils/code-utils";
import { MoneyUtils } from "../../utils/money-utils";


export interface DiscountSet {
  discount: CatalogDiscount;
  rule: CatalogPricingRule;
  code?: CatalogDiscountCode;
}

export interface DiscountEngineOptions {
	order: Order;
	discountSets: DiscountSet[];
	orderingType: OrderFulfillmentType;
	catalogCategoryMenuMap: Map<string, CatalogMenu>;
	catalogItemMap: Map<string, CatalogItem>;
}

class DiscountEngine {
  /**
   * Determines the discounts.
   */
  public checkAndApplyDiscounts({
    order,
    discountSets,
    orderingType,
    catalogCategoryMenuMap,
    catalogItemMap,
  }: DiscountEngineOptions): DiscountSet[] {
    const eligibleDiscountSets: DiscountSet[] = [];

    // clear all discounts
    order.discounts = [];
    order.lineItems?.forEach((lineItem) => {
      lineItem.appliedDiscounts = [];
      lineItem.totalDiscountMoney = { amount: 0, currency: lineItem.totalMoney.currency };
    });

    // find eligible DiscountSets
    for (const discountSet of discountSets) {
      if (this.checkTriggerType(discountSet.rule, discountSet.code) && this.checkServiceType(discountSet.rule, orderingType)) {
        eligibleDiscountSets.push(discountSet);
      }
    }

    for (const discountSet of eligibleDiscountSets) {
      let discountedSubtotalMoney: Money = order.totalDiscountMoney ? MoneyUtils.subtract(order.subtotalMoney, order.totalDiscountMoney) : order.subtotalMoney;
      discountedSubtotalMoney = order.totalVoucherMoney ? MoneyUtils.subtract(discountedSubtotalMoney, order.totalVoucherMoney) : discountedSubtotalMoney;

      if (this.checkMinBasketAmountMoney(discountSet.rule, discountedSubtotalMoney)) {
        const { discount } = discountSet;
        // UID is generated for each discount and is connecting the discount with the applied discounts
        const orderLineItemDiscountUid: string = CodeUtils.generateUid();

        const orderLineItemDiscount: OrderLineItemDiscount | undefined = {
          uid: orderLineItemDiscountUid,
          catalogDiscountId: discount.id,
          name: discount.name,
          scope: OrderLineItemDiscountScopeConstants.ITEM, // until now there is only ITEM as scope
          type: discount.discountType,
          percentage: discount.discountType === CatalogDiscountType.FIXED_PERCENTAGE ? (discount.percentage || null) : null,
          amountMoney: discount.discountType === CatalogDiscountType.FIXED_AMOUNT ? (discount.amountMoney || null) : null,
          appliedMoney: { amount: 0, currency: order.subtotalMoney.currency },
        };

        let lineItemAppliedDiscountMap: Map<OrderLineItem, OrderLineItemAppliedDiscount> | undefined = undefined;

        switch (orderLineItemDiscount.scope) {
          case OrderLineItemDiscountScopeConstants.ITEM:
            lineItemAppliedDiscountMap = this.calcAppliedLineItemScopeDiscount(orderLineItemDiscountUid, order, discountSet, catalogCategoryMenuMap, catalogItemMap);
            break;
          case OrderLineItemDiscountScopeConstants.ORDER:
            lineItemAppliedDiscountMap = this.calcAppliedOrderScopeDiscount(orderLineItemDiscountUid, order, discountSet, discountedSubtotalMoney);
            break;
        }

        if (lineItemAppliedDiscountMap) {
          // adds discounts to related line items
          lineItemAppliedDiscountMap.forEach((orderLineItemAppliedDiscount, lineItem) => {
            lineItem.appliedDiscounts = [
              ...lineItem.appliedDiscounts,
              orderLineItemAppliedDiscount,
            ];
            lineItem.totalDiscountMoney = MoneyUtils.add(lineItem.totalDiscountMoney, orderLineItemAppliedDiscount.appliedMoney);
            orderLineItemDiscount.appliedMoney = MoneyUtils.add(orderLineItemDiscount.appliedMoney, orderLineItemAppliedDiscount.appliedMoney);
          });
        }
        order.discounts.push(orderLineItemDiscount);

        this.updateLineItemTotals(order.lineItems);
        this.updateTotals(order);
      }
    }

    this.updateLineItemTotals(order.lineItems);
    this.updateTotals(order);

    return eligibleDiscountSets;
  }

  private checkTriggerType(rule: CatalogPricingRule, code?: CatalogDiscountCode): boolean {
    return rule.triggerType === CatalogPricingRuleTriggerType.AUTO ||
      (rule.triggerType === CatalogPricingRuleTriggerType.CODE && !!code);
  }

  private checkServiceType(rule: CatalogPricingRule, orderFulfillmentType: OrderFulfillmentType): boolean {
    const types = rule.matchFulfillmentTypes?.split(',') || [];
    return types.includes(orderFulfillmentType);
  }

  private checkMinBasketAmountMoney(rule: CatalogPricingRule, subtotal: Money): boolean {
    return !rule.prerequisiteSubtotalMinMoney || rule.prerequisiteSubtotalMinMoney.amount < subtotal.amount;
  }

  private checkMatchProducts(rule: CatalogPricingRule, itemId: string, categoryId?: string, menuId?: string): boolean {
    return rule.matchProducts.productIdsAny.includes(itemId) ||
      (categoryId && rule.matchProducts.productIdsAny.includes(categoryId)) ||
      (menuId && rule.matchProducts.productIdsAny.includes(menuId));
  }

  private checkQuantityMin(rule: CatalogPricingRule, quantity: number): boolean {
    return !rule.matchProducts?.quantityMin ||
      quantity >= rule.matchProducts.quantityMin;
  }

  private checkQuantityExact(rule: CatalogPricingRule, quantity: number): boolean {
    return !rule.matchProducts?.quantityExact
      || quantity === rule.matchProducts.quantityExact;
  }

  private calcAppliedLineItemScopeDiscount(
    uid: string,
    order: Order,
    { discount, rule }: DiscountSet,
    catalogCategoryMenuMap: Map<string, CatalogMenu>,
    catalogItemMap: Map<string, CatalogItem>,
  ): Map<OrderLineItem, OrderLineItemAppliedDiscount> {
    const lineItemAppliedDiscountMap: Map<OrderLineItem, OrderLineItemAppliedDiscount> = new Map();
    let totalAppliedMoney: Money = new Money();

    // finds line items the discount is applied to
    const foundLineItems: OrderLineItem[] = [];
    if (rule.matchProducts) {
      if (rule.matchProducts.allProducts) {
        foundLineItems.push(...order.lineItems);
      } else if (rule.matchProducts.productIdsAny) {
        for (const lineItem of order.lineItems) {
          const catalogItem: CatalogItem | undefined = catalogItemMap.get(lineItem.itemId);
          const catalogMenu: CatalogMenu | undefined = catalogItem ? catalogCategoryMenuMap.get(catalogItem.categoryId) : undefined;

          // check if item id, category id or menu id of an item is in the list of product ids
          if (this.checkMatchProducts(rule, lineItem.itemId, catalogItem?.categoryId, catalogMenu?.id) &&
            this.checkQuantityMin(rule, lineItem.quantity) &&
            this.checkQuantityExact(rule, lineItem.quantity)
          ) {
            foundLineItems.push(lineItem);
          }
        }
      }
    }

    // sorts lineItems by exclude-strategy
    foundLineItems.sort((a: OrderLineItem, b: OrderLineItem) =>
      rule.excludeStrategy === CatalogPricingRuleExcludeStrategyConstant.LEAST_EXPENSIVE
        ? (a.grossSalesMoney.amount / a.quantity) - (b.grossSalesMoney.amount / b.quantity)
        : (b.grossSalesMoney.amount / b.quantity) - (a.grossSalesMoney.amount / a.quantity));

    // check quantityMax
    let quantityMax: number | undefined =
      rule.matchProducts && rule.matchProducts.quantityMax !== undefined && rule.matchProducts.quantityMax !== null
        ? rule.matchProducts.quantityMax
        : undefined;

    // creates all discounts applied to the line items
    for (const lineItem of foundLineItems) {
      const appliedMoney: Money = this.calcDiscountAmountPerLineItem(discount, lineItem, quantityMax);

      const orderLineItemAppliedDiscount: OrderLineItemAppliedDiscount = {
        discountUid: uid,
        appliedMoney,
      };
      lineItemAppliedDiscountMap.set(lineItem, orderLineItemAppliedDiscount);
      totalAppliedMoney = MoneyUtils.add(totalAppliedMoney, appliedMoney);

      if (quantityMax !== undefined) {
        quantityMax -= lineItem.quantity;
        if (quantityMax <= 0) {
          break;
        }
      }
    }

    // checks max discount amount and reduces if necessary
    if (discount.amountMoneyMax && totalAppliedMoney.amount > discount.amountMoneyMax.amount) {
      const diff: Money = MoneyUtils.subtract(totalAppliedMoney, discount.amountMoneyMax);
      this.distributeReduction(lineItemAppliedDiscountMap, diff);
    }

    return lineItemAppliedDiscountMap;
  }

  private calcAppliedOrderScopeDiscount(
    uid: string,
    order: Order,
    { discount }: DiscountSet,
    discountedSubtotalMoney: Money,
  ): Map<OrderLineItem, OrderLineItemAppliedDiscount> {
    const lineItemAppliedDiscountMap: Map<OrderLineItem, OrderLineItemAppliedDiscount> = new Map();

    const appliedMoney: Money = this.calcDiscountAmount(discount, discountedSubtotalMoney);

    // check max discount amount and reduces if necessary
    if (discount.amountMoneyMax && appliedMoney.amount > discount.amountMoneyMax.amount) {
      appliedMoney.amount = discount.amountMoneyMax.amount;
    }

    // creates all discounts applied to the line items
    for (const lineItem of order.lineItems) {
      const orderLineItemAppliedDiscount: OrderLineItemAppliedDiscount = {
        discountUid: uid,
        appliedMoney: { amount: 0, currency: appliedMoney.currency }
      };
      lineItemAppliedDiscountMap.set(lineItem, orderLineItemAppliedDiscount);
    }

    this.distributeDiscount(lineItemAppliedDiscountMap, appliedMoney);

    return lineItemAppliedDiscountMap;
  }

  /**
   * Distributes a discount amount to the applied discounts of the line items.
   *
   * @param lineItemAppliedDiscountMap the map with line items and their applied discounts
   * @param discountMoney the discount amount
   */
  private distributeDiscount(lineItemAppliedDiscountMap: Map<OrderLineItem, OrderLineItemAppliedDiscount>, discountMoney: Money): Money {
    let totalMoney: Money = { amount: 0, currency: discountMoney.currency };
    lineItemAppliedDiscountMap.forEach((orderLineItemAppliedDiscount, lineItem) => {
      totalMoney = MoneyUtils.add(totalMoney, lineItem.totalMoney);
      totalMoney = MoneyUtils.subtract(totalMoney, orderLineItemAppliedDiscount.appliedMoney);
    });
  
    let remainingDiscountMoney: Money = discountMoney;
  
    if (totalMoney.amount > 0) {
      const ratio: number = remainingDiscountMoney.amount / totalMoney.amount;
  
      let index = 0;
      lineItemAppliedDiscountMap.forEach((orderLineItemAppliedDiscount, lineItem) => {
        const lineItemTotalMoney: Money = MoneyUtils.subtract(lineItem.totalMoney, orderLineItemAppliedDiscount.appliedMoney);
  
        let discount: Money = index === lineItemAppliedDiscountMap.size - 1 ?
          remainingDiscountMoney :
          MoneyUtils.multScalar(ratio, lineItemTotalMoney);
        if (discount.amount > lineItemTotalMoney.amount) {
          discount = lineItemTotalMoney;
        }
        if (discount.amount > remainingDiscountMoney.amount) {
          discount = remainingDiscountMoney;
        }
  
        orderLineItemAppliedDiscount.appliedMoney = MoneyUtils.add(orderLineItemAppliedDiscount.appliedMoney, discount);
        remainingDiscountMoney = MoneyUtils.subtract(remainingDiscountMoney, discount);
        index++;
      });
  
      if (remainingDiscountMoney.amount > 0) {
        remainingDiscountMoney = this.distributeDiscount(lineItemAppliedDiscountMap, remainingDiscountMoney);
      }
    }
  
    return remainingDiscountMoney;
  }
  
  /**
   * Distributes a reduction amount to the applied discounts of the line items.
   *
   * @param lineItemAppliedDiscountMap the map with line items and their applied discounts
   * @param reductionMoney the reduction amount
   */
  private distributeReduction(lineItemAppliedDiscountMap: Map<OrderLineItem, OrderLineItemAppliedDiscount>, reductionMoney: Money): Money {
    let totalAppliedMoney: Money = { amount: 0, currency: reductionMoney.currency };
    lineItemAppliedDiscountMap.forEach((discount) => totalAppliedMoney = MoneyUtils.add(totalAppliedMoney, discount.appliedMoney));

    let remainingReductionMoney: Money = reductionMoney;

    if (totalAppliedMoney.amount > 0) {
      const ratio: number = remainingReductionMoney.amount / totalAppliedMoney.amount;

      let index = 0;
      lineItemAppliedDiscountMap.forEach((orderLineItemAppliedDiscount) => {
        let reduction: Money = index === lineItemAppliedDiscountMap.size - 1 ?
          remainingReductionMoney :
          MoneyUtils.multScalar(ratio, orderLineItemAppliedDiscount.appliedMoney);
        if (reduction.amount > orderLineItemAppliedDiscount.appliedMoney.amount) {
          reduction = orderLineItemAppliedDiscount.appliedMoney;
        }
        if (reduction.amount > remainingReductionMoney.amount) {
          reduction = remainingReductionMoney;
        }

        orderLineItemAppliedDiscount.appliedMoney = MoneyUtils.subtract(orderLineItemAppliedDiscount.appliedMoney, reduction);
        remainingReductionMoney = MoneyUtils.subtract(remainingReductionMoney, reduction);
        index++;
      });

      if (remainingReductionMoney.amount > 0) {
        remainingReductionMoney = this.distributeReduction(lineItemAppliedDiscountMap, remainingReductionMoney);
      }
    }

    return remainingReductionMoney;
  }

  /**
   * Calculates the discount amount for a line item.
   *
   * @param discount the discount for calculation
   * @param lineItem the line item the discount should be applied to
   * @param quantityMax if set then only this many objects within the line item will get the discount
   */
  private calcDiscountAmountPerLineItem(
    discount: CatalogDiscount,
    lineItem: OrderLineItem,
    quantityMax: number | undefined,
  ): Money {
    let appliedMoney: Money | undefined;

    const baseGrossSalesMoneyOfOne: Money = MoneyUtils.multScalar(1 / lineItem.quantity, lineItem.grossSalesMoney);
    appliedMoney = this.calcDiscountAmount(discount, baseGrossSalesMoneyOfOne);

    if (quantityMax !== undefined && quantityMax < lineItem.quantity) {
      appliedMoney = MoneyUtils.multScalar(quantityMax, appliedMoney);
    } else {
      appliedMoney = MoneyUtils.multScalar(lineItem.quantity, appliedMoney);
    }

    return appliedMoney;
  }

  /**
   * Calculates the discount amount for an order.
   *
   * @param discount the discount for calculation
   * @param amountMoney the amount the discount should be applied to
   */
  private calcDiscountAmount(discount: CatalogDiscount, amountMoney: Money): Money {
    let appliedMoney: Money = { amount: 0, currency: amountMoney.currency };

    if (discount.discountType === CatalogDiscountType.FIXED_PERCENTAGE && discount.percentage !== undefined && discount.percentage !== null) {
      appliedMoney = MoneyUtils.multScalar(discount.percentage / 100.0, amountMoney);
    } else if (discount.discountType === CatalogDiscountType.FIXED_AMOUNT && discount.amountMoney !== undefined && discount.amountMoney !== null) {
      appliedMoney = {
        amount: Math.min(discount.amountMoney.amount, amountMoney.amount),
        currency: amountMoney.currency,
      };
    }

    return appliedMoney;
  }

  // update all line item totals
  private updateLineItemTotals(lineItems: OrderLineItem[]): void {
    for (const lineItem of lineItems) {
      let totalDiscountMoney: Money = { amount: 0, currency: lineItem.totalDiscountMoney.currency };
      let totalVoucherMoney: Money = { amount: 0, currency: lineItem.totalVoucherMoney.currency };

      if (lineItem.appliedDiscounts) {
        for (const discount of lineItem.appliedDiscounts) {
          totalDiscountMoney = MoneyUtils.add(discount.appliedMoney, totalDiscountMoney);
        }
      }
      if (lineItem.appliedVouchers) {
        for (const voucher of lineItem.appliedVouchers) {
          totalVoucherMoney = MoneyUtils.add(voucher.appliedMoney, totalVoucherMoney);
        }
      }

      lineItem.totalDiscountMoney = totalDiscountMoney;
      lineItem.totalVoucherMoney = totalVoucherMoney;
      const totalPromotionMoney = MoneyUtils.add(totalDiscountMoney, totalVoucherMoney);
      lineItem.totalMoney = lineItem.grossSalesMoney ?
        MoneyUtils.subtract(lineItem.grossSalesMoney, totalPromotionMoney) :
        MoneyUtils.multScalar(-1, totalPromotionMoney);
    }
  }

  private updateTotals(order: Order): void {
    let subtotalMoney: Money = { amount: 0, currency: order.subtotalMoney.currency };
    let totalDiscountMoney: Money = { amount: 0, currency: order.totalDiscountMoney?.currency || order.subtotalMoney.currency };
    let totalVoucherMoney: Money = { amount: 0, currency: order.totalVoucherMoney?.currency || order.subtotalMoney.currency };
    let totalMoney: Money = { amount: 0, currency: order.totalMoney.currency };

    order.lineItems?.forEach((lineItem: OrderLineItem) => {
      subtotalMoney = MoneyUtils.add(subtotalMoney, lineItem.grossSalesMoney);
    });

    order.discounts?.forEach((lineItemDiscount: OrderLineItemDiscount) => {
      totalDiscountMoney = MoneyUtils.add(totalDiscountMoney, lineItemDiscount.appliedMoney);
    });

    order.vouchers?.forEach((lineItemVoucher: OrderLineItemVoucher) => {
      totalVoucherMoney = MoneyUtils.add(totalVoucherMoney, lineItemVoucher.appliedMoney);
    });

    totalMoney = MoneyUtils.multScalar(1, subtotalMoney);
    totalMoney = MoneyUtils.subtract(totalMoney, totalDiscountMoney);

    order.subtotalMoney = subtotalMoney;
    order.totalDiscountMoney = totalDiscountMoney;
    order.totalMoney = totalMoney;
  }
}

export const discountEngine = new DiscountEngine();
