import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { classToPlain } from 'class-transformer';

import { Event } from '../models/event';
import { User } from '../models/user';
import { Order, OrderFulfillmentPaymentMethod, OrderStatusConstants, Tender, TenderTypeConstants } from '../models/order';
import { OrderReceipt } from '../models/order-receipt';
import { EventTemplate } from '../models/event-template';
import { environment } from '../../../environments/environment';


/**
 * Class providing access methods to the database.
 */
@Injectable({
  providedIn: 'root'
})
export class DatabaseAccess {

  private userCollection: AngularFirestoreCollection<User>;
  private eventCollection: AngularFirestoreCollection<Event>;
  private eventTemplateCollection: AngularFirestoreCollection<EventTemplate>;
  private orderCollection: AngularFirestoreCollection<Order>;
  private orderReceiptCollection: AngularFirestoreCollection<Order>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
  ) {
    this.userCollection = afs.collection<User>('users');
    this.eventCollection = afs.collection<Event>('events');
    this.eventTemplateCollection = afs.collection<EventTemplate>('eventTemplates');
    this.orderCollection = afs.collection<Order>('orders');
    this.orderReceiptCollection = afs.collection<Order>('orderReceipts');
  }

  /**
   * Returns events of a user.
   *
   * @param userId the user ID
   * @returns the found events, otherwise empty list
   */
  public getEventsOfUser(userId: string): Observable<Event[]> {
    return this.afs.collection<Event>('events', ref => ref.where('memberIds', 'array-contains', userId).orderBy('createdAt', 'desc'))
      .valueChanges().pipe(
        map((eventsJson) => {
          return eventsJson as Event[]; // plainToClass(Event, eventsJson as object[]);
        })
      );
  }

  /**
   * Returns an event.
   *
   * @param eventId the event ID
   * @returns the found event, otherwise undefined
   */
  public getEvent(eventId: string): Observable<Event | undefined> {
    return this.eventCollection.doc(eventId).valueChanges().pipe(
      map((eventJson) => {
        return eventJson as Event; // plainToClass(Event, eventJson);
      })
    );
  }

  /**
   * Adds an event.
   *
   * @param event the event to add
   * @returns the added event
   */
  public async addEvent(event: Event): Promise<Event> {
    if (!event.id) {
      event.id = this.afs.createId();
      event.createdAt = new Date().toISOString();
    }
    event.updatedAt = new Date().toISOString();

    await this.eventCollection.doc(event.id).set(classToPlain(event) as Event, { merge: true });

    // START: to move to cloud
    const user: User | null = await this.getUser(event.userId).pipe(first()).toPromise();
    user.eventIds.push(event.id);
    await this.updateUser(user);
    // END

    return event;
  }

  /**
   * Updates an event.
   *
   * @param event the event to update
   * @returns the updated event
   */
  public async updateEvent(event: Event): Promise<Event> {
    await this.eventCollection.doc(event.id).set(classToPlain(event) as Event, { merge: true });

    return event;
  }

  /**
   * Removes an event.
   *
   * @param eventId the ID of the event to remove
   */
  public async removeEvent(eventId: string): Promise<void> {
    await this.eventCollection.doc(eventId).delete();
  }

  /**
   * Returns an event template.
   *
   * @param templateId the event template ID
   * @returns the found event template, otherwise undefined
   */
  public getEventTemplate(templateId: string): Observable<EventTemplate | undefined> {
    return this.eventTemplateCollection.doc(templateId).valueChanges().pipe(
      map((eventTemplateJson) => {
        return eventTemplateJson as EventTemplate; // plainToClass(EventTemplate, eventTemplateJson);
      })
    );
  }

  /**
   * Returns all Orders of an Event.
   *
   * @param eventId the ID of the Event
   * @returns the found Orders, otherwise empty list
   */
  public getAllOrdersOfEvent(eventId: string): Observable<Order[]> {
    return this.eventCollection.doc(eventId).collection<Order>('orders', ref => ref.orderBy('createdAt', 'desc'))
      .valueChanges().pipe(
        map((ordersJson) => {
          return (ordersJson as Order[]).map(this.makeOrderDownwardCompatible); // plainToClass(Order, ordersJson as object[]);
        })
      );
  }

  /**
   * Returns all Orders of a Customer.
   *
   * @param customerId the ID of the Customer
   * @returns the found Orders, otherwise empty list
   */
  public getOrdersOfCustomer(customerId: string): Observable<Order[]> {
    return this.afs.collection<Order>('orders', ref => ref.where('customerId', '==', customerId).orderBy('createdAt', 'desc'))
      .valueChanges().pipe(
        map((ordersJson) => {
          return (ordersJson as Order[]).map(this.makeOrderDownwardCompatible); // plainToClass(Order, ordersJson as object[]);
        })
      );
  }

  /**
   * Returns an Order specified by the ID.
   *
   * @param orderId the Order ID
   * @returns the found Order, otherwise undefined
   */
  public getOrder(orderId: string): Observable<Order | undefined> {
    return this.orderCollection.doc(orderId).valueChanges().pipe(
      map((orderJson) => {
        return this.makeOrderDownwardCompatible(orderJson as Order); // plainToClass(Order, orderJson);
      })
    );
  }

  /**
   * Adds an order to the specified event.
   *
   * @param order the order to add
   * @param eventId the event ID
   * @returns the added order
   */
  public async addOrder(order: Order, eventId?: string): Promise<string> {
    if (!order.id) {
      order.id = this.afs.createId();
      order.createdAt = new Date().toISOString();
    }
    order.updatedAt = new Date().toISOString();

    await this.orderCollection.doc(order.id).set(classToPlain(order) as Order, { merge: true });

    if (eventId) {
      // START: to move to cloud
      const event: Event | undefined = await this.getEvent(order.eventId).pipe(first()).toPromise();
      if (event !== undefined) {
        const orders: Order[] = await this.getAllOrdersOfEvent(order.eventId).pipe(first()).toPromise();
        event.totalSum = 0;
//      orders.forEach((eventOrder: Order) => event.totalSum += eventOrder.price === undefined ? 0 : eventOrder.totalCount * eventOrder.price);

        await this.updateEvent(event);
      }
      // END
    }

    return order.id;
  }

  /**
   * Updates the order of the specified event.
   *
   * @param order the order to update
   * @param eventId the event ID
   * @returns the updated order
   */
  public async updateOrder(order: Order, eventId?: string): Promise<string> {
    order.updatedAt = new Date().toISOString();

    await this.orderCollection.doc(order.id).set(classToPlain(this.makeOrderUpwardCompatible(order)) as Order, {merge: true});

    if (eventId) {
      // START: to move to cloud
      const event: Event | undefined = await this.getEvent(order.eventId).pipe(first()).toPromise();
      if (event !== undefined) {
        const orders: Order[] = await this.getAllOrdersOfEvent(order.eventId).pipe(first()).toPromise();
        event.totalSum = 0;
//      orders.forEach((eventOrder: Order) => event.totalSum += eventOrder.price === undefined ? 0 : eventOrder.totalCount * eventOrder.price);

        await this.updateEvent(event);
      }
      // END
    }

    return order.id;
  }

  /**
   * Places an order.
   *
   * @param order the order to place
   * @returns the updated order
   */
  public async placeOrder(order: Order): Promise<string> {
    order.updatedAt = new Date().toISOString();
    const compatibleOrder = this.makeOrderUpwardCompatible(order);
    
    await this.http.post(
      `${environment.firebaseEndpoint.placeOrder}/?key=${environment.firebaseEndpoint.apiKey}`,
      compatibleOrder
    ).toPromise();
    return order.id;
  }

  /**
   * Removes an order.
   *
   * @param eventId the event ID
   * @param orderId the order ID
   */
  public async removeOrder(eventId: string, orderId: string): Promise<void> {
    await this.orderCollection.doc(orderId).delete();
  }

  /**
   * Returns an OrderReceipt specified by the ID.
   *
   * @param orderReceiptId the OrderReceipt ID
   * @returns the found OrderReceipt, otherwise undefined
   */
  public getOrderReceipt(orderReceiptId: string): Observable<OrderReceipt> {
    return this.orderReceiptCollection.doc(orderReceiptId).valueChanges().pipe(
      map((orderReceiptJson) => {
        return orderReceiptJson as OrderReceipt; // plainToClass(OrderReceipt, orderReceiptJson);
      })
    );
  }

  /**
   * Add new OrderReceipt to cloud.
   *
   * @param orderReceipt the OrderReceipt object to add
   * @return the id of the new OrderReceipt
   */
  public async addOrderReceipt(orderReceipt: OrderReceipt): Promise<string> {
    if (!orderReceipt.id) {
      orderReceipt.id = this.afs.createId();
      orderReceipt.createdAt = new Date().toISOString();
    }
    orderReceipt.updatedAt = new Date().toISOString();

    await this.orderReceiptCollection.doc(orderReceipt.id).set(classToPlain(orderReceipt) as OrderReceipt, { merge: true });

    return orderReceipt.id;
  }

  /**
   * Removes OrderReceipts.
   *
   * @param orderReceiptIds the IDs of the OrderReceipts to remove
   */
  public async removeOrderReceipts(orderReceiptIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const orderReceiptId of orderReceiptIds) {
      batch.delete(this.orderReceiptCollection.doc(orderReceiptId).ref);
    }

    await batch.commit();
  }

  /**
   * Returns all users of the specified account.
   *
   * @param accountId the account ID
   * @returns all users of the account, otherwise empty list
   */
  public getUsersOfAccount(accountId: string): Observable<User[]> {
    return this.afs.collection<User>('users', ref => ref.where('accountId', '==', accountId))
      .valueChanges().pipe(
        map((usersJson) => {
          return usersJson as User[]; // plainToClass(User, usersJson as object[]);
        })
      );
  }

  /**
   * Returns all users of the specified event.
   *
   * @param eventId the event ID
   * @returns all users of the event, otherwise empty list
   */
  public getUsersOfEvent(eventId: string): Observable<User[]> {
    return this.afs.collection<User>('users', ref => ref.where('eventIds', 'array-contains', eventId))
      .valueChanges().pipe(
        map((usersJson) => {
          return usersJson as User[]; // plainToClass(User, usersJson as object[]);
        })
      );
  }

  /**
   * Returns all users.
   *
   * @returns the found users, otherwise empty list
   */
  public getAllUsers(): Observable<User[]> {
    return this.afs.collection<User>('users', ref => ref.orderBy('lastname'))
      .valueChanges().pipe(
        map((usersJson) => {
          return usersJson as User[]; // plainToClass(User, usersJson as object[]);
        })
      );
  }

  /**
   * Returns a user.
   *
   * @param userId the user ID
   * @returns the found user, otherwise undefined
   */
  public getUser(userId: string): Observable<User | undefined> {
    return this.userCollection.doc(userId).valueChanges().pipe(
      map((userJson) => {
        return userJson as User; // plainToClass(User, userJson);
      })
    );
  }

  /**
   * Returns a user by looking for its email address.
   *
   * @param email the email address of the user
   * @returns the found user, otherwise undefined
   */
  public getUserByEmail(email: string): Observable<User | undefined> {
    return this.afs.collection<User>('users', ref => ref.where('email', '==', email))
      .valueChanges().pipe(
        map((usersJson) => {
          const users: User[] = usersJson as User[]; // plainToClass(User, usersJson as object[]);

          return users.length > 0 ? users[0] : undefined;
        })
      );
  }

  /**
   * Adds a user.
   *
   * @param user the user to add
   * @returns the added user
   */
  public async addUser(user: User): Promise<User> {
    if (!user.id) {
      user.id = this.afs.createId();
      user.createdAt = new Date().toISOString();
    }
    user.updatedAt = new Date().toISOString();

    await this.userCollection.doc(user.id).set(classToPlain(user) as User, { merge: true });

    return user;
  }

  /**
   * Updates a user.
   *
   * @param user the user to update
   * @returns the updated user
   */
  public async updateUser(user: User): Promise<User> {
    user.updatedAt = new Date().toISOString();

    await this.userCollection.doc(user.id).set(classToPlain(user) as User, { merge: true });

    return user;
  }

  /**
   * Removes users.
   *
   * @param userIds the IDs of the users to remove
   */
  public async removeUsers(userIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const userId of userIds) {
      batch.delete(this.userCollection.doc(userId).ref);
    }

    await batch.commit();
  }

  private makeOrderDownwardCompatible(order: Order): Order {
    if (order.fulfillment) {
      order.fulfillments = [order.fulfillment];
    }

    if ([OrderStatusConstants.PLACED, OrderStatusConstants.ACCEPTED, OrderStatusConstants.CANCELLED].includes(order.status)) {
      if (!order.tenders || order.tenders.length === 0) {
        const tender: Tender = new Tender();
        tender.type = order.fulfillment?.preferredPaymentMethod === OrderFulfillmentPaymentMethod.ON_SITE_EC ? TenderTypeConstants.ON_SITE_EC : TenderTypeConstants.ON_SITE;
        tender.isPrepaid = false;
        tender.directCharge = true;
        tender.amountMoney = order.totalMoney;
        tender.createdAt = order.placedAt || new Date().toISOString();
        order.tenders = [tender];
      }

      order.status = OrderStatusConstants.OPEN;
    }

    return order;
  }

  private makeOrderUpwardCompatible(order: Order): Order {
    if (order.fulfillments && order.fulfillments.length > 0) {
      order.fulfillment = order.fulfillments[0];
    }

    // if (order.status === OrderStatusConstants.OPEN) {
    //   if (order.fulfillment?.status === 'PROPOSED' && order.tenders && order.tenders.length > 0) {
    //     order.status = OrderStatusConstants.PLACED;
    //   } else if (order.fulfillment?.status === 'RESERVED') {
    //     order.status = OrderStatusConstants.ACCEPTED;
    //   } else if (order.fulfillment?.status === 'CANCELLED') {
    //     order.status = OrderStatusConstants.CANCELLED;
    //   }
    // }

    // // remove tender with type ON_SITE_EC and ON_SITE
    // order.tenders = order.tenders?.filter((tender: Tender) => {
    //   return tender.type !== TenderTypeConstants.ON_SITE_EC && tender.type !== TenderTypeConstants.ON_SITE;
    // }) || [];

    return order;
  }
}
