import { CatalogTimePeriod } from './catalog-time-period';
import { CatalogProductSet } from './catalog-product-set';
import { LanguageDictionary } from './language-dictionary';
import { Money } from './money';


/**
 * Indicates how the rule will be triggered.
 */
export enum CatalogPricingRuleTriggerType {
  /** Apply the discount automatically if one or more conditions are fulfilled. (default) */
  AUTO = 'AUTO',
  /** Apply the discount after the customer has entered a valid discount code belonging to this rule. */
  CODE = 'CODE'
}

/**
 * If an excludeProducts was given, controls which subset of matched products is excluded from any discounts.
 * Default value: LEAST_EXPENSIVE
 */
export enum CatalogPricingRuleExcludeStrategyConstant {
  /**
   * The least expensive matched products are excluded from the pricing.
   * If the pricing rule is set to exclude one product and multiple products in the match set qualify as least expensive,
   * then one will be excluded at random.
   * Excluding the least expensive product gives the best discount value to the buyer.
   */
  LEAST_EXPENSIVE = 'LEAST_EXPENSIVE',
  /**
   * The most expensive matched product is excluded from the pricing rule.
   * If multiple products have the same price and all qualify as least expensive, one will be excluded at random.
   * This guarantees that the most expensive product is purchased at full price.
   */
  MOST_EXPENSIVE = 'MOST_EXPENSIVE'
}

export interface CatalogPricingRuleEligibility {
  /**
   * List of authorized customer IDs.
   * If the field is empty or not present, the rule applies to all customers.
   * Optional: If group assignments are also relevant, you can
   * add further fields (e.g. `eligibleCustomerGroupIds`).
   */
  eligibleCustomerIds?: string[] | null;
  // eligibleCustomerGroupIds?: string[];

  /**
   * List of authorized reservation IDs.
   * If the field is empty or not present, the rule applies to all reservations.
   */
  eligibleReservationIds?: string[] | null;
}

/**
 * Defines how prices are modified or set for items that match the pricing rule during the active time period.
 */
export class CatalogPricingRule {

  /** The unique ID of this object. */
  public id: string;
  /** The account's unique ID the object belongs to. */
  public accountId: string;
  /**
   * Unique ID for the CatalogDiscount to take off the price of all matched items.
   * @deprecated CatalogDiscount and CatalogVoucher are referencing to this object.
   */
  public discountId?: string | null;
  /** User-defined name for the pricing rule. For example, "Buy one get one free" or "10% off". */
  public name: string;

  /**
   * Indicates how the rule will be triggered.
   *
   * AUTO: Apply the discount automatically if one or more conditions are fulfilled. (default)
   * CODE: Apply the discount after the customer has entered a valid discount code belonging to this rule.
   */
  public triggerType: CatalogPricingRuleTriggerType = CatalogPricingRuleTriggerType.AUTO;

  /**
   * A list of unique IDs for the catalog time periods when this pricing rule is in effect.
   * If left unset, the pricing rule is always in effect.
   */
  public timePeriods?: CatalogTimePeriod[];

  /**
   * Comma-separated fulfillment types that will be matched by this rule.
   * Possible values: DINE_IN, PICKUP, SHIPMENT.
   */
  public matchFulfillmentTypes?: string;

  /**
   * Defines the dependencies for the pricing rule.
   */
  public eligible?: CatalogPricingRuleEligibility | null;

  /**
   * The CatalogProductSet that will be matched by this rule. A match rule matches within the entire cart.
   * A match rule can match multiple times in the cart. If no ProductSet is present, the rule will match all products.
   */
  public matchProducts?: CatalogProductSet;
  /**
   * CatalogProductSet to exclude from the pricing rule.
   * An exclude rule matches within the subset of the cart that fits the match rules (the match set).
   * An exclude rule can only match once in the match set. If not supplied, the pricing will be applied to all products in the match set.
   * Other products retain their base price, or a price generated by other rules.
   */
  public excludeProducts?: CatalogProductSet;

  /** Represents the date the Pricing Rule is valid from. Represented in RFC3339 full-date format (YYYY-MM-DD). */
  public validFromDate?: string;

  /**
   * Represents the local time the pricing rule should be valid from. Represented in RFC3339 partial-time format (HH:MM:SS).
   * Partial seconds will be truncated.
   */
  public validFromLocalTime?: string;

  /** Represents the date the Pricing Rule is valid until. Represented in RFC3339 full-date format (YYYY-MM-DD). */
  public validUntilDate?: string;

  /**
   * Represents the local time the pricing rule should be valid until. Represented in RFC3339 partial-time format (HH:MM:SS).
   * Partial seconds will be truncated.
   */
  public validUntilLocalTime?: string;

  /**
   * The minimum subtotal for the price rule to be applicable.
   * The subtotal of the entitled cart items must be greater than or equal to this value for the discount to apply.
   */
  public prerequisiteSubtotalMinMoney?: Money;

  /**
   * If an excludeProducts was given, controls which subset of matched products is excluded from any discounts.
   * Default value: LEAST_EXPENSIVE
   *
   * LEAST_EXPENSIVE: The least expensive matched products are excluded from the pricing.
   *   If the pricing rule is set to exclude one product and multiple products in the match set qualify as least expensive,
   *   then one will be excluded at random.
   *   Excluding the least expensive product gives the best discount value to the buyer.
   * MOST_EXPENSIVE: The most expensive matched product is excluded from the pricing rule.
   *   If multiple products have the same price and all qualify as least expensive, one will be excluded at random.
   *   This guarantees that the most expensive product is purchased at full price.
   */
  public excludeStrategy: CatalogPricingRuleExcludeStrategyConstant = CatalogPricingRuleExcludeStrategyConstant.LEAST_EXPENSIVE;

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;

  /** The default language code in ISO-639-1 format. */
  public defaultLang: string;
  public strings: LanguageDictionary[];

}
