import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

import { environment } from '../../../environments/environment';
import { StorageImage } from '../models/storage-image';


/**
 * Directive for an StorageImage from the Cloud.
 */
@Directive({
  selector: '[appCloudImage]'
})
export class CloudImageDirective {

  private img: StorageImage;
  private prop: string;

  // tslint:disable-next-line:no-input-rename
  @Input('appCloudImage') public set image(data: {img: StorageImage, prop?: string}) {
    this.img = data.img;
    this.prop = data.prop ? data.prop : '';
    this.updateImage();
  }

  constructor(
    protected el: ElementRef,
    protected renderer: Renderer2
  ) {
  }

  private updateImage(): void {
    let url: string = this.img.url;
    const cloudName: string = environment.cloudinaryConfig.cloudName;

    if (this.img.refs) {
      for (const refObject of this.img.refs) {
        if (refObject.name === environment.apiStorageConfig.imageRefName) {
          url = `${environment.apiStorageConfig.baseUrl}${this.prop}/${refObject.ref}`;
          break;
        } else if (refObject.name === environment.cloudinaryConfig.imageRefName) {
          url = `https://res.cloudinary.com/${cloudName}/image/upload/${this.prop}/${refObject.ref}.jpg`;
        }
      }
    }

    this.renderer.setAttribute(this.el.nativeElement, 'src', url);
  }
}
