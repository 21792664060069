import { CatalogItem, CatalogTaxOverride } from "../../models/catalog-item";
import { CatalogTax } from "../../models/catalog-tax";
import { Money } from "../../models/money";
import { Order, OrderFulfillmentType, OrderLineItemTax } from "../../models/order";
import { OrderLineItemAppliedTax } from "../../models/order-line-item";
import { CodeUtils } from "../../utils/code-utils";
import { MoneyUtils } from "../../utils/money-utils";


export interface TaxEngineOptions {
	order: Order;
	catalogTaxMap: Map<string, CatalogTax>;
	orderingType: OrderFulfillmentType;
	catalogItemMap: Map<string, CatalogItem>;
}

class TaxEngine {
  /**
   * Determines the taxes.
   *
   * @param order the order
   * @param catalogTaxMap the map with CatalogTaxes
   * @param orderingType the ordering type
   * @param catalogItemMap the map with CatalogItems
   */
  public checkAndApplyTaxes({
    order,
    catalogTaxMap,
    orderingType,
    catalogItemMap,
  }: TaxEngineOptions): void {
    // clear all taxes
    order.taxes = [];
    order.lineItems?.forEach((lineItem) => {
      lineItem.appliedTaxes = [];
      lineItem.totalTaxMoney = { amount: 0, currency: lineItem.totalMoney.currency };
    });

    const orderLineItemTaxMap: Map<string, OrderLineItemTax> = new Map();

    if (order.lineItems) {
      order.lineItems.forEach((lineItem) => {
        let totalTaxAdditiveMoney = { amount: 0, currency: lineItem.totalMoney.currency };

        const catalogItem: CatalogItem | undefined = catalogItemMap.get(lineItem.itemId);
        if (catalogItem && catalogItem.taxIds && catalogItem.taxIds.length > 0) {
          const catalogTaxes: CatalogTax[] = [];

          // handle fulfillment type specific tax IDs
          let taxIds: string[] = catalogItem.taxIds;
          if (catalogItem.taxOverrides) {
            const taxOverride: CatalogTaxOverride | undefined = catalogItem.taxOverrides.find((override: CatalogTaxOverride) => {
              return override.fulfillmentType === orderingType;
            });
            if (taxOverride && taxOverride.taxIds) {
              taxIds = taxOverride.taxIds;
            }
          }

          for (const taxId of taxIds) {
            const tax: CatalogTax | undefined = catalogTaxMap.get(taxId);
            if (tax) {
              catalogTaxes.push(tax);
            }
          }
          const taxMoneyMap: Map<string, Money> = this.calcTaxAmounts(
            lineItem.totalMoney,
            catalogTaxes.filter((tax: CatalogTax) => tax.inclusionType === 'INCLUSIVE')
          );

          for (const tax of catalogTaxes) {
            let orderLineItemTax: OrderLineItemTax | undefined = orderLineItemTaxMap.get(tax.id);
            if (orderLineItemTax === undefined) {
              orderLineItemTax = {
                uid: CodeUtils.generateUid(),
                catalogTaxId: tax.id,
                name: tax.name,
                scope: 'LINE_ITEM', // until now there is only LINE_ITEM as scope
                type: tax.inclusionType,
                percentage: tax.percentage,
                appliedMoney: { amount: 0, currency: lineItem.totalMoney.currency },
              };

              orderLineItemTaxMap.set(orderLineItemTax.catalogTaxId, orderLineItemTax);
            }

            const orderLineItemAppliedTax: OrderLineItemAppliedTax = {
              taxUid: orderLineItemTax.uid,
              appliedMoney: { amount: 0, currency: lineItem.totalMoney.currency },
            };

            if (tax.inclusionType === 'INCLUSIVE') {
              orderLineItemAppliedTax.appliedMoney = taxMoneyMap.get(tax.id);
            } else if (tax.inclusionType === 'ADDITIVE') {
              orderLineItemAppliedTax.appliedMoney = MoneyUtils.multScalar(tax.percentage / 100.0, lineItem.totalMoney);
              totalTaxAdditiveMoney = MoneyUtils.add(totalTaxAdditiveMoney, orderLineItemAppliedTax.appliedMoney);
            }

            lineItem.appliedTaxes = [
              ...lineItem.appliedTaxes,
              orderLineItemAppliedTax,
            ];
            orderLineItemTax.appliedMoney = MoneyUtils.add(orderLineItemTax.appliedMoney, orderLineItemAppliedTax.appliedMoney);
          }
        }

        // update line item total
        lineItem.totalTaxMoney = new Money();

        if (lineItem.appliedTaxes) {
          for (const appliedTax of lineItem.appliedTaxes) {
            lineItem.totalTaxMoney = MoneyUtils.add(appliedTax.appliedMoney, lineItem.totalTaxMoney);
          }
        }

        lineItem.totalMoney = MoneyUtils.add(lineItem.totalMoney, totalTaxAdditiveMoney);
      });
    }

    order.taxes = Array.from(orderLineItemTaxMap.values());

    this.updateTotalTaxMoney(order);
  }

  /**
   * Calculates the tax amounts.
   */
  private calcTaxAmounts(grossMoney: Money, taxes: CatalogTax[]): Map<string, Money> {
    const taxMoneyMap: Map<string, Money> = new Map();

    let totalPercentage = 0;
    for (const tax of taxes) {
      totalPercentage += tax.percentage;
    }
    // Calculates the net amount: 100*gross = (100 + p1 + p2 + ... + px)*net
    const netMoney: Money = MoneyUtils.multScalar(100 / (100 + totalPercentage), grossMoney);
    let remainMoney: Money = MoneyUtils.subtract(grossMoney, netMoney);
    for (let i = 0; i < taxes.length; i++) {
      const taxMoney: Money = MoneyUtils.multScalar(taxes[i].percentage / 100.0, netMoney);
      if (i >= taxes.length - 1 || taxMoney.amount > remainMoney.amount) {
        taxMoney.amount = remainMoney.amount;
      }
      remainMoney = MoneyUtils.subtract(remainMoney, taxMoney);

      taxMoneyMap.set(taxes[i].id, taxMoney);
    }

    return taxMoneyMap;
  }

  /**
   * Updates the total tax money.
   */
  private updateTotalTaxMoney(order: Order): void {
    let totalTaxMoney = { amount: 0, currency: order.totalMoney.currency };

    for (const tax of order.taxes) {
      totalTaxMoney = MoneyUtils.add(totalTaxMoney, tax.appliedMoney);
    }

    order.totalTaxMoney = totalTaxMoney;
  }
}

export const taxEngine = new TaxEngine();
