import { CatalogDiscountType } from "../../models/catalog-discount";
import { CatalogDiscountCode } from "../../models/catalog-discount-code";
import { CatalogItem } from "../../models/catalog-item";
import { CatalogMenu } from "../../models/catalog-menu";
import { CatalogPricingRule, CatalogPricingRuleExcludeStrategyConstant, CatalogPricingRuleTriggerType } from "../../models/catalog-pricing-rule";
import { CatalogVoucher, VoucherBehaviorType } from "../../models/catalog-voucher";
import { Money } from "../../models/money";
import { Order, OrderFulfillmentType, OrderLineItemDiscount, OrderLineItemVoucher } from "../../models/order";
import { OrderLineItem, OrderLineItemAppliedVoucher } from "../../models/order-line-item";
import { CodeUtils } from "../../utils/code-utils";
import { MoneyUtils } from "../../utils/money-utils";


export interface VoucherSet {
  voucher: CatalogVoucher;
  rule?: CatalogPricingRule;
  code?: CatalogDiscountCode;
}

export interface VoucherEngineOptions {
	order: Order;
	voucherSets: VoucherSet[];
	orderingType: OrderFulfillmentType;
	catalogCategoryMenuMap: Map<string, CatalogMenu>;
	catalogItemMap: Map<string, CatalogItem>;
}

class VoucherEngine {
  /**
   * Determines the vouchers.
   */
  public checkAndApplyVouchers({
    order,
    voucherSets,
    orderingType,
    catalogCategoryMenuMap,
    catalogItemMap,
  }: VoucherEngineOptions): VoucherSet[] {
    const eligibleVoucherSets: VoucherSet[] = [];

    // clear all vouchers
    order.vouchers = [];
    order.lineItems?.forEach((lineItem) => {
      lineItem.appliedVouchers = [];
      lineItem.totalVoucherMoney = { amount: 0, currency: lineItem.totalMoney.currency };
    });

    // find eligible VoucherSets
    for (const voucherSet of voucherSets) {
      if (voucherSet.voucher.behavior === VoucherBehaviorType.DISCOUNT && voucherSet.voucher.currentBalanceMoney.amount > 0) {
        const newVoucherSet: VoucherSet = {
          voucher: voucherSet.voucher,
          rule: voucherSet.rule || {
            id: voucherSet.voucher.id,
            accountId: voucherSet.voucher.accountId,
            name: voucherSet.voucher.name,
            triggerType: CatalogPricingRuleTriggerType.AUTO,
            excludeStrategy: CatalogPricingRuleExcludeStrategyConstant.LEAST_EXPENSIVE,
            matchProducts: {
              allProducts: true,
            },
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            defaultLang: voucherSet.voucher.defaultLang,
            strings: []
          },
          code: voucherSet.code,
        };
        if (this.checkTriggerType(newVoucherSet.rule, newVoucherSet.code) &&
          this.checkServiceType(newVoucherSet.rule, orderingType) &&
          this.checkEligibility(newVoucherSet.rule, order.reservationId)
        ) {
          eligibleVoucherSets.push(newVoucherSet);
        }
      } else {
        // TODO: implement VoucherBehaviorType.PAYMENT_METHOD case
      }
    }

    for (const voucherSet of eligibleVoucherSets) {
      let discountedSubtotalMoney: Money = order.totalDiscountMoney ? MoneyUtils.subtract(order.subtotalMoney, order.totalDiscountMoney) : order.subtotalMoney;
      discountedSubtotalMoney = order.totalVoucherMoney ? MoneyUtils.subtract(discountedSubtotalMoney, order.totalVoucherMoney) : discountedSubtotalMoney;

      if (this.checkMinBasketAmountMoney(voucherSet.rule, discountedSubtotalMoney)) {
        const { voucher } = voucherSet;
        // UID is generated for each voucher and is connecting the voucher with the applied vouchers
        const orderLineItemVoucherUid: string = CodeUtils.generateUid();

        const orderLineItemVoucher: OrderLineItemVoucher | undefined = {
          uid: orderLineItemVoucherUid,
          catalogVoucherId: voucher.id,
          name: voucher.name,
          type: CatalogDiscountType.FIXED_AMOUNT,
          amountMoney: voucher.currentBalanceMoney,
          appliedMoney: { amount: 0, currency: order.subtotalMoney.currency },
        };

        let lineItemAppliedVoucherMap: Map<OrderLineItem, OrderLineItemAppliedVoucher> | undefined = undefined;

        lineItemAppliedVoucherMap = this.calcAppliedLineItemScopeVoucher(orderLineItemVoucherUid, order, voucherSet, catalogCategoryMenuMap, catalogItemMap);

        if (lineItemAppliedVoucherMap) {
          // adds vouchers to related line items
          lineItemAppliedVoucherMap.forEach((orderLineItemAppliedVoucher, lineItem) => {
            lineItem.appliedVouchers = [
              ...lineItem.appliedVouchers,
              orderLineItemAppliedVoucher,
            ];
            lineItem.totalVoucherMoney = MoneyUtils.add(lineItem.totalVoucherMoney, orderLineItemAppliedVoucher.appliedMoney);
            orderLineItemVoucher.appliedMoney = MoneyUtils.add(orderLineItemVoucher.appliedMoney, orderLineItemAppliedVoucher.appliedMoney);
          });
        }
        order.vouchers.push(orderLineItemVoucher);

        this.updateLineItemTotals(order.lineItems);
        this.updateTotals(order);
      }
    }

    this.updateLineItemTotals(order.lineItems);
    this.updateTotals(order);

    return eligibleVoucherSets;
  }

  private checkTriggerType(rule: CatalogPricingRule, code?: CatalogDiscountCode): boolean {
    return rule.triggerType === CatalogPricingRuleTriggerType.AUTO ||
      (rule.triggerType === CatalogPricingRuleTriggerType.CODE && !!code);
  }

  private checkServiceType(rule: CatalogPricingRule, orderFulfillmentType: OrderFulfillmentType): boolean {
    const types = rule.matchFulfillmentTypes?.split(',') || [];
    return types.includes(orderFulfillmentType);
  }

  private checkEligibility(rule: CatalogPricingRule, reservationId?: string): boolean {
    return !rule.eligible?.eligibleReservationIds ||
      (reservationId && rule.eligible.eligibleReservationIds.includes(reservationId));
  }

  private checkMinBasketAmountMoney(rule: CatalogPricingRule, subtotal: Money): boolean {
    return !rule.prerequisiteSubtotalMinMoney || rule.prerequisiteSubtotalMinMoney.amount < subtotal.amount;
  }

  private checkMatchProducts(rule: CatalogPricingRule, itemId: string, categoryId?: string, menuId?: string): boolean {
    return rule.matchProducts.productIdsAny.includes(itemId) ||
      (categoryId && rule.matchProducts.productIdsAny.includes(categoryId)) ||
      (menuId && rule.matchProducts.productIdsAny.includes(menuId));
  }

  private checkQuantityMin(rule: CatalogPricingRule, quantity: number): boolean {
    return !rule.matchProducts?.quantityMin ||
      quantity >= rule.matchProducts.quantityMin;
  }

  private checkQuantityExact(rule: CatalogPricingRule, quantity: number): boolean {
    return !rule.matchProducts?.quantityExact
      || quantity === rule.matchProducts.quantityExact;
  }

  private calcAppliedLineItemScopeVoucher(
    uid: string,
    order: Order,
    { voucher, rule }: VoucherSet,
    catalogCategoryMenuMap: Map<string, CatalogMenu>,
    catalogItemMap: Map<string, CatalogItem>,
  ): Map<OrderLineItem, OrderLineItemAppliedVoucher> {
    const lineItemAppliedVoucherMap: Map<OrderLineItem, OrderLineItemAppliedVoucher> = new Map();
    let totalAppliedMoney: Money = new Money();

    // finds line items the voucher is applied to
    const foundLineItems: OrderLineItem[] = [];
    if (rule.matchProducts) {
      if (rule.matchProducts.allProducts) {
        foundLineItems.push(...order.lineItems);
      } else if (rule.matchProducts.productIdsAny) {
        for (const lineItem of order.lineItems) {
          const catalogItem: CatalogItem | undefined = catalogItemMap.get(lineItem.itemId);
          const catalogMenu: CatalogMenu | undefined = catalogItem ? catalogCategoryMenuMap.get(catalogItem.categoryId) : undefined;

          // check if item id, category id or menu id of an item is in the list of product ids
          if (this.checkMatchProducts(rule, lineItem.itemId, catalogItem?.categoryId, catalogMenu?.id) &&
            this.checkQuantityMin(rule, lineItem.quantity) &&
            this.checkQuantityExact(rule, lineItem.quantity)
          ) {
            foundLineItems.push(lineItem);
          }
        }
      }
    }

    // sorts lineItems by exclude-strategy
    foundLineItems.sort((a: OrderLineItem, b: OrderLineItem) =>
      rule.excludeStrategy === CatalogPricingRuleExcludeStrategyConstant.LEAST_EXPENSIVE
        ? (a.grossSalesMoney.amount / a.quantity) - (b.grossSalesMoney.amount / b.quantity)
        : (b.grossSalesMoney.amount / b.quantity) - (a.grossSalesMoney.amount / a.quantity));

    // check quantityMax
    let quantityMax: number | undefined =
      rule.matchProducts && rule.matchProducts.quantityMax !== undefined && rule.matchProducts.quantityMax !== null
        ? rule.matchProducts.quantityMax
        : undefined;

    // creates all vouchers applied to the line items
    for (const lineItem of foundLineItems) {
      const appliedMoney: Money = this.calcVoucherAmountPerLineItem(voucher, lineItem, quantityMax);

      const orderLineItemAppliedVoucher: OrderLineItemAppliedVoucher = {
        voucherUid: uid,
        appliedMoney,
      };
      lineItemAppliedVoucherMap.set(lineItem, orderLineItemAppliedVoucher);
      totalAppliedMoney = MoneyUtils.add(totalAppliedMoney, appliedMoney);

      if (quantityMax !== undefined) {
        quantityMax -= lineItem.quantity;
        if (quantityMax <= 0) {
          break;
        }
      }
    }

    // checks max voucher amount and reduces if necessary
    if (voucher.currentBalanceMoney && totalAppliedMoney.amount > voucher.currentBalanceMoney.amount) {
      const diff: Money = MoneyUtils.subtract(totalAppliedMoney, voucher.currentBalanceMoney);
      this.distributeReduction(lineItemAppliedVoucherMap, diff);
    }

    return lineItemAppliedVoucherMap;
  }

  /**
   * Distributes a reduction amount to the applied vouchers of the line items.
   *
   * @param lineItemAppliedVoucherMap the map with line items and their applied vouchers
   * @param reductionMoney the reduction amount
   */
  private distributeReduction(lineItemAppliedVoucherMap: Map<OrderLineItem, OrderLineItemAppliedVoucher>, reductionMoney: Money): Money {
    let totalAppliedMoney: Money = { amount: 0, currency: reductionMoney.currency };
    lineItemAppliedVoucherMap.forEach((voucher) => totalAppliedMoney = MoneyUtils.add(totalAppliedMoney, voucher.appliedMoney));

    let remainingReductionMoney: Money = reductionMoney;

    if (totalAppliedMoney.amount > 0) {
      const ratio: number = remainingReductionMoney.amount / totalAppliedMoney.amount;

      let index = 0;
      lineItemAppliedVoucherMap.forEach((orderLineItemAppliedVoucher) => {
        let reduction: Money = index === lineItemAppliedVoucherMap.size - 1 ?
          remainingReductionMoney :
          MoneyUtils.multScalar(ratio, orderLineItemAppliedVoucher.appliedMoney);
        if (reduction.amount > orderLineItemAppliedVoucher.appliedMoney.amount) {
          reduction = orderLineItemAppliedVoucher.appliedMoney;
        }
        if (reduction.amount > remainingReductionMoney.amount) {
          reduction = remainingReductionMoney;
        }

        orderLineItemAppliedVoucher.appliedMoney = MoneyUtils.subtract(orderLineItemAppliedVoucher.appliedMoney, reduction);
        remainingReductionMoney = MoneyUtils.subtract(remainingReductionMoney, reduction);
        index++;
      });

      if (remainingReductionMoney.amount > 0) {
        remainingReductionMoney = this.distributeReduction(lineItemAppliedVoucherMap, remainingReductionMoney);
      }
    }

    return remainingReductionMoney;
  }

  /**
   * Calculates the voucher amount for a line item.
   *
   * @param voucher the voucher for calculation
   * @param lineItem the line item the voucher should be applied to
   * @param quantityMax if set then only this many objects within the line item will get the voucher
   */
  private calcVoucherAmountPerLineItem(
    voucher: CatalogVoucher,
    lineItem: OrderLineItem,
    quantityMax: number | undefined,
  ): Money {
    let appliedMoney: Money | undefined;

    const baseGrossSalesMoneyOfOne: Money = MoneyUtils.multScalar(1 / lineItem.quantity, lineItem.grossSalesMoney);
    appliedMoney = this.calcVoucherAmount(voucher, baseGrossSalesMoneyOfOne);

    if (quantityMax !== undefined && quantityMax < lineItem.quantity) {
      appliedMoney = MoneyUtils.multScalar(quantityMax, appliedMoney);
    } else {
      appliedMoney = MoneyUtils.multScalar(lineItem.quantity, appliedMoney);
    }

    return appliedMoney;
  }

  /**
   * Calculates the voucher amount for an order.
   *
   * @param voucher the voucher for calculation
   * @param amountMoney the amount the voucher should be applied to
   */
  private calcVoucherAmount(voucher: CatalogVoucher, amountMoney: Money): Money {
    let appliedMoney: Money = { amount: 0, currency: amountMoney.currency };

    appliedMoney = {
      amount: Math.min(voucher.currentBalanceMoney.amount, amountMoney.amount),
      currency: amountMoney.currency,
    };

    return appliedMoney;
  }

  // update all line item totals
  private updateLineItemTotals(lineItems: OrderLineItem[]): void {
    for (const lineItem of lineItems) {
      let totalDiscountMoney: Money = { amount: 0, currency: lineItem.totalDiscountMoney.currency };
      let totalVoucherMoney: Money = { amount: 0, currency: lineItem.totalVoucherMoney.currency };

      if (lineItem.appliedDiscounts) {
        for (const discount of lineItem.appliedDiscounts) {
          totalDiscountMoney = MoneyUtils.add(discount.appliedMoney, totalDiscountMoney);
        }
      }
      if (lineItem.appliedVouchers) {
        for (const voucher of lineItem.appliedVouchers) {
          totalVoucherMoney = MoneyUtils.add(voucher.appliedMoney, totalVoucherMoney);
        }
      }

      lineItem.totalDiscountMoney = totalDiscountMoney;
      lineItem.totalVoucherMoney = totalVoucherMoney;
      const totalPromotionMoney = MoneyUtils.add(totalDiscountMoney, totalVoucherMoney);
      lineItem.totalMoney = lineItem.grossSalesMoney ?
        MoneyUtils.subtract(lineItem.grossSalesMoney, totalPromotionMoney) :
        MoneyUtils.multScalar(-1, totalPromotionMoney);
    }
  }

  private updateTotals(order: Order): void {
    let subtotalMoney: Money = { amount: 0, currency: order.subtotalMoney.currency };
    let totalDiscountMoney: Money = { amount: 0, currency: order.totalDiscountMoney?.currency || order.subtotalMoney.currency };
    let totalVoucherMoney: Money = { amount: 0, currency: order.totalVoucherMoney?.currency || order.subtotalMoney.currency };
    let totalMoney: Money = { amount: 0, currency: order.totalMoney.currency };

    order.lineItems?.forEach((lineItem: OrderLineItem) => {
      subtotalMoney = MoneyUtils.add(subtotalMoney, lineItem.grossSalesMoney);
    });

    order.discounts?.forEach((lineItemDiscount: OrderLineItemDiscount) => {
      totalDiscountMoney = MoneyUtils.add(totalDiscountMoney, lineItemDiscount.appliedMoney);
    });

    order.vouchers?.forEach((lineItemVoucher: OrderLineItemVoucher) => {
      totalVoucherMoney = MoneyUtils.add(totalVoucherMoney, lineItemVoucher.appliedMoney);
    });

    totalMoney = MoneyUtils.multScalar(1, subtotalMoney);
    totalMoney = MoneyUtils.subtract(totalMoney, totalDiscountMoney);
    totalMoney = MoneyUtils.subtract(totalMoney, totalVoucherMoney);

    order.subtotalMoney = subtotalMoney;
    order.totalDiscountMoney = totalDiscountMoney;
    order.totalVoucherMoney = totalVoucherMoney;
    order.totalMoney = totalMoney;
  }
}

export const voucherEngine = new VoucherEngine();
