import { Money } from './money';
import { LanguageDictionary } from './language-dictionary';


/**
 * Indicates whether the discount is a fixed amount or percentage, or entered at the time of sale.
 */
export enum CatalogDiscountType {
  /** Used for reporting only. The original transaction discount type is currently not supported by the API. */
  UNKNOWN_DISCOUNT = 'UNKNOWN_DISCOUNT',
  /** Apply the discount as a fixed percentage (e.g., 5%) off the item price. */
  FIXED_PERCENTAGE = 'FIXED_PERCENTAGE',
  /** Apply the discount as a fixed amount (e.g., $1.00) off the item price. */
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  /** Apply the discount as a variable percentage off the item price. The percentage will be specified at the time of sale. */
  VARIABLE_PERCENTAGE = 'VARIABLE_PERCENTAGE',
  /** Apply the discount as a variable amount off the item price. The amount will be specified at the time of sale. */
  VARIABLE_AMOUNT = 'VARIABLE_AMOUNT',
}

/**
 * Indicates whether this discount should reduce the price used to calculate tax.
 *
 * Most discounts should use MODIFY_TAX_BASIS. However, in some circumstances taxes must be calculated based on an item's price,
 * ignoring a particular discount. For example, in many US jurisdictions, a manufacturer coupon or instant rebate reduces
 * the price a customer pays but does not reduce the sale price used to calculate how much sales tax is due.
 * In this case, the discount representing that manufacturer coupon should have DO_NOT_MODIFY_TAX_BASIS for this field.
 *
 * If you are unsure whether you need to use this field, consult your tax professional.
 */
export enum CatalogDiscountModifyTaxBasisConstant {
  MODIFY_TAX_BASIS = 'MODIFY_TAX_BASIS',
  DO_NOT_MODIFY_TAX_BASIS = 'DO_NOT_MODIFY_TAX_BASIS'
}

/**
 * A discount in the Catalog object model.
 */
export class CatalogDiscount {

  /** The unique ID of this object. */
  public id: string;
  /** The account's unique ID the object belongs to. */
  public accountId: string;
  /** The ID of the pricing rule that created the discount. */
  public pricingRuleId: string;
  /** The discount name. Searchable. This field has max length of 255 Unicode code points. */
  public name: string;
  /**
   * Indicates whether the discount is a fixed amount or percentage, or entered at the time of sale.
   *
   * FIXED_PERCENTAGE: Apply the discount as a fixed percentage (e.g., 5%) off the item price.
   * FIXED_AMOUNT: Apply the discount as a fixed amount (e.g., $1.00) off the item price.
   * VARIABLE_PERCENTAGE: Apply the discount as a variable percentage off the item price. The percentage will be specified at the time of sale.
   * VARIABLE_AMOUNT: Apply the discount as a variable amount off the item price. The amount will be specified at the time of sale.
   */
  public discountType: CatalogDiscountType;
  /**
   * The percentage of the discount.
   * A value of 7.5 corresponds to 7.5%. Specify a percentage of 0 if discountType is VARIABLE_PERCENTAGE.
   *
   * Do not include this field for amount-based or variable discounts.
   */
  public percentage?: number | null;
  /**
   * The amount of the discount. Specify an amount of 0 if discountType is VARIABLE_AMOUNT.
   *
   * Do not include this field for percentage-based or variable discounts.
   */
  public amountMoney?: Money | null;
  /**
   * The maximum total discount amount to be applicable to the order.
   */
  public amountMoneyMax?: Money | null;
  /** The color of the discount display label in the Square Point of Sale app. This must be a valid hex color code. */
  public labelColor?: string | null;
  /**
   * Indicates whether this discount should reduce the price used to calculate tax.
   *
   * Most discounts should use MODIFY_TAX_BASIS. However, in some circumstances taxes must be calculated based on an item's price,
   * ignoring a particular discount. For example, in many US jurisdictions, a manufacturer coupon or instant rebate reduces
   * the price a customer pays but does not reduce the sale price used to calculate how much sales tax is due.
   * In this case, the discount representing that manufacturer coupon should have DO_NOT_MODIFY_TAX_BASIS for this field.
   *
   * If you are unsure whether you need to use this field, consult your tax professional.
   */
  public modifyTaxBasis: CatalogDiscountModifyTaxBasisConstant = CatalogDiscountModifyTaxBasisConstant.MODIFY_TAX_BASIS;

  /**
   * If true, this object is present at all locations (including future locations),
   * except where specified in the absent_at_location_ids field.
   * If false, this object is not present at any locations (including future locations),
   * except where specified in the present_at_location_ids field. If not specified, defaults to true.
   */
  public presentAtAllLocations?: boolean | null;

  /**
   * A list of locations where the object is present, even if present_at_all_locations is false.
   */
  public presentAtLocationIds?: string[] | null;

  /**
   * A list of locations where the object is not present, even if present_at_all_locations is true.
   */
  public absentAtLocationIds?: string[] | null;

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;

  /** The default language code in ISO-639-1 format. */
  public defaultLang: string;
  public strings: LanguageDictionary[];

}
