import { Type } from 'class-transformer';

import { OrderLineItem } from './order-line-item';
import { Address } from './address';
import { Money } from './money';
import { Card } from './card';


/**
 * The tender type.
 */
export enum TenderTypeConstants {
  /** Cash tender. */
  CASH = 'CASH',
  /** Card tender. */
  CARD = 'CARD',
  /** Wallet tender. */
  WALLET = 'WALLET',
  /** On site payment. @deprecated */
  ON_SITE = 'ON_SITE',
  /** On site with EC payment. @deprecated */
  ON_SITE_EC = 'ON_SITE:EC',
  /** Other tender. */
  OTHER = 'OTHER',
}

/**
 * The tender type.
 */
export enum OrderFulfillmentPaymentMethod {
  /** Card tender. */
  CARD = 'CARD',
  /** Wallet tender. */
  PAYPAL = 'PAYPAL',
  /** On site payment. */
  ON_SITE = 'ON_SITE',
  /** On site with EC payment. */
  ON_SITE_EC = 'ON_SITE:EC',
}

/**
 * The OrderLineItem discount scope.
 */
export enum OrderLineItemDiscountScopeConstants {
  /** The discount should be applied to the entire order. */
  ORDER = 'ORDER',
  /** The discount should be applied to only line items specified by OrderLineItemAppliedDiscount reference records. */
  LINE_ITEM = 'LINE_ITEM',
  /** The discount should be applied to only line items specified by OrderLineItemAppliedDiscount reference records. */
  ITEM = 'ITEM',
  /** Used for reporting only. The original transaction discount scope is currently not supported by the API. */
  OTHER_DISCOUNT_SCOPE = 'OTHER_DISCOUNT_SCOPE'
}

export enum OrderFulfillmentType {
  DINE_IN = 'DINE_IN',
  PICKUP = 'PICKUP',
  SHIPMENT = 'SHIPMENT'
}

/**
 * The Order status.
 */
export enum OrderStatusConstants {
  /** Newly created order. */
  OPEN = 'OPEN',
  /** Order placed by the customer. */
  PLACED = 'PLACED',
  /** Order accepted by the user. */
  ACCEPTED = 'ACCEPTED',
  /** Order cancelled by customer or user. */
  CANCELLED = 'CANCELLED',
  /** Order completed by the clearing service. */
  COMPLETED = 'COMPLETED',
  /** Order deactivated by platform. */
  DEACTIVATED = 'DEACTIVATED'
}

/**
 * Represents a discount that applies to one or more line items in an order.
 */
export class OrderLineItemDiscount {
  /** Unique ID that identifies the discount only within this order. */
  public uid: string;
  /** The catalog object id referencing CatalogDiscount. */
  public catalogDiscountId: string;
  /** The name of the discount. */
  public name: string;
  /**
   * The type of the discount.
   *
   * Discounts that don't reference a catalog object ID must have a type of FIXED_PERCENTAGE or FIXED_AMOUNT.
   *
   * UNKNOWN_DISCOUNT: Used for reporting only. The original transaction discount type is currently not supported by the API.
   * FIXED_PERCENTAGE: Apply the discount as a fixed percentage (e.g., 5%) off the item price.
   * FIXED_AMOUNT: Apply the discount as a fixed monetary value (e.g., $1.00) off the item price.
   * VARIABLE_PERCENTAGE: Apply the discount as a variable percentage based on the item price.
   *   The specific discount percentage of a VARIABLE_PERCENTAGE discount is assigned at the time of the purchase.
   * VARIABLE_AMOUNT: Apply the discount as a variable amount based on the item price.
   *   The specific discount amount of a VARIABLE_AMOUNT discount is assigned at the time of the purchase.
   */
  public type: string;
  /**
   * The percentage of the discount, as a string representation of a decimal number. A value of 7.25 corresponds to a percentage of 7.25%.
   *
   * percentage is not set for amount-based discounts.
   */
  public percentage?: number | null;
  /**
   * The total declared monetary amount of the discount.
   * amountMoney is not set for percentage-based discounts.
   */
  public amountMoney?: Money | null;
  /**
   * The amount of discount actually applied to the line item.
   *
   * Represents the amount of money applied as a line item-scoped discount.
   * When an amount-based discount is scoped to the entire order, the value of appliedMoney is different from amountMoney
   * because the total amount of the discount is distributed across all line items.
   */
  public appliedMoney: Money = new Money();
  /**
   * Indicates the level at which the discount applies. For ORDER scoped discounts,
   * Square generates references in appliedDiscounts on all order line items that do not have them.
   * For LINE_ITEM scoped discounts, the discount only applies to line items with a discount reference in their appliedDiscounts field.
   *
   * This field is immutable. To change the scope of a discount you must delete the discount and re-add it as a new discount.
   */
  public scope: OrderLineItemDiscountScopeConstants;
}

/**
 * Represents a voucher that applies to one or more line items in an order.
 */
export class OrderLineItemVoucher {
  /** Unique ID that identifies the voucher only within this order. */
  public uid: string;
  /** The catalog object id referencing CatalogVoucher. */
  public catalogVoucherId: string;
  /** The name of the voucher. */
  public name: string;
  /**
   * The type of the voucher.
   *
   * Vouchers that don't reference a catalog object ID must have a type of FIXED_PERCENTAGE or FIXED_AMOUNT.
   *
   * UNKNOWN_DISCOUNT: Used for reporting only. The original transaction discount type is currently not supported by the API.
   * FIXED_PERCENTAGE: Apply the discount as a fixed percentage (e.g., 5%) off the item price.
   * FIXED_AMOUNT: Apply the discount as a fixed monetary value (e.g., $1.00) off the item price.
   * VARIABLE_PERCENTAGE: Apply the discount as a variable percentage based on the item price.
   *   The specific discount percentage of a VARIABLE_PERCENTAGE discount is assigned at the time of the purchase.
   * VARIABLE_AMOUNT: Apply the discount as a variable amount based on the item price.
   *   The specific discount amount of a VARIABLE_AMOUNT discount is assigned at the time of the purchase.
   */
  public type: string;
  /**
   * The percentage of the voucher, as a string representation of a decimal number. A value of 7.25 corresponds to a percentage of 7.25%.
   *
   * percentage is not set for amount-based vouchers.
   */
  public percentage?: number | null;
  /**
   * The total declared monetary amount of the voucher.
   * amountMoney is not set for percentage-based vouchers.
   */
  public amountMoney?: Money | null;
  /**
   * The amount of voucher actually applied to the line item.
   *
   * Represents the amount of money applied as a line item-scoped voucher.
   * When an amount-based voucher is scoped to the entire order, the value of appliedMoney is different from amountMoney
   * because the total amount of the voucher is distributed across all line items.
   */
  public appliedMoney: Money = new Money();
}

/**
 * Represents a tax that applies to one or more line items in an order.
 */
export class OrderLineItemTax {
  /** Unique ID that identifies the tax only within this order. */
  public uid: string;
  /** The catalog object id referencing CatalogTax. */
  public catalogTaxId: string;
  /** The tax's name. */
  public name: string;
  /**
   * Indicates the calculation method used to apply the tax.
   *
   * UNKNOWN_TAX: Used for reporting only. The original transaction tax type is currently not supported by the API.
   * ADDITIVE: The tax is an additive tax. The tax amount is added on top of the price.
   *   For example, an item with a cost of 1.00 USD and a 10% additive tax would have a total cost to the buyer of 1.10 USD.
   * INCLUSIVE: The tax is an inclusive tax. Inclusive taxes are already included in the line item price or order total.
   *   For example, an item with cost 1.00 USD with a 10% inclusive tax would have a pre-tax cost of 0.91 USD (91 cents)
   *   and a 0.09 (9 cents) tax for a total cost of 1.00 USD to the buyer.
   */
  public type: string;
  /**
   * The percentage of the tax, as a string representation of a decimal number.
   * For example, a value of "7.25" corresponds to a percentage of 7.25%.
   */
  public percentage: number;
  /**
   * The amount of the money applied by the tax in the order.
   *
   * Represents the amount of money applied as a line item-scoped discount.
   * When an amount-based discount is scoped to the entire order, the value of appliedMoney is different from amountMoney
   * because the total amount of the discount is distributed across all line items.
   */
  public appliedMoney: Money = new Money();
  /**
   * Indicates the level at which the tax applies. For ORDER scoped taxes,
   * Square generates references in applied_taxes on all order line items that do not have them.
   * For LINE_ITEM scoped taxes, the tax will only apply to line items with references in their applied_taxes field.
   *
   * This field is immutable. To change the scope, you must delete the tax and re-add it as a new tax
   *
   * OTHER_TAX_SCOPE: Used for reporting only. The original transaction tax scope is currently not supported by the API.
   * LINE_ITEM: The tax should be applied only to line items specified by the OrderLineItemAppliedTax reference records.
   */
  public scope: string;
}

/**
 * An application fee collected on top of a charge.
 */
export class ApplicationFee {
  /** The name of the service charge. */
  public name: string;
  /**
   * The amount of a non-percentage based service charge.
   * Exactly one of percentage or amount_money should be set.
   */
  public amountMoney?: Money;
  /**
   * The service charge percentage as a string representation of a decimal number. For example, "7.25" indicates a service charge of 7.25%.
   * Exactly 1 of percentage or amount_money should be set.
   */
  public percentage?: number;
  /**
   * The amount of money applied to the order by the service charge, including any inclusive tax amounts, as calculated by platform.
   *
   * For fixed-amount service charges, applied_money is equal to amount_money.
   * For percentage-based service charges, applied_money is the money calculated using the percentage.
   */
  public appliedMoney: Money = new Money();
  // /** The total amount of tax money to collect for the service charge. */
  // public totalTaxMoney: Money = new Money();
  // /**
  //  * The total amount of money to collect for the service charge.
  //  * Note: if an inclusive tax is applied to the service charge, totalMoney does not equal appliedMoney plus totalTaxMoney
  //  * since the inclusive tax amount will already be included in both applied_money and total_tax_money.
  //  */
  // public totalMoney: Money = new Money();
}

/**
 * Represents additional details of a tender with type CARD or GIFT_CARD.
 */
export class TenderCardDetails {
  /** The credit card's non-confidential details. */
  public card: Card;
}

/**
 * Represents additional details of a tender with type WALLET.
 */
export class TenderWalletDetails {
  /** The wallet type. */
  public type: string;
  /** The email address of the wallet owner. */
  public emailAddress: string;
  /** The email address of the payee. */
  public payeeEmailAddress: string;
}

/**
 * The Tenders which were used to pay for the Order.
 */
export class Tender {
  /**
   * The type of tender, such as CARD, CASH or
   * WALLET (A payment from a digital wallet, e.g. Cash App.
   * Note: Some "digital wallets", including Google Pay and Apple Pay, facilitate card payments. Those payments have the CARD type.).
   */
  public type: TenderTypeConstants;

  /** The ID of the Payment that corresponds to this tender. */
  public paymentId?: string;
  /** The ID of the tender's associated transaction. */
  public transactionId?: string;
  /**
   * The total amount of the tender, including tip_money. If the tender has a payment_id,
   * the total_money of the corresponding Payment will be equal to the amount_money of the tender.
   */
  public amountMoney: Money = new Money();
  /** The tip's amount of the tender. */
  public tipMoney: Money = new Money();

  /** Indicates whether payment is done before service execution. */
  public isPrepaid = false;
  /** Indicates whether to create direct charges on a connected account. */
  public directCharge = false;

  /**
   * The details of the card tender.
   * This value is present only if the value of type is CARD.
   */
  public cardDetails?: TenderCardDetails;

  /**
   * The details of the wallet tender.
   * This value is present only if the value of type is WALLET.
   */
  public walletDetails?: TenderWalletDetails;

  /** The billing address for this tender. */
  public billingAddress?: Address;
  /** The billing email address for this tender. */
  public billingEmail?: string;

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

  /** A list of application fees applied to the charge. */
  @Type(() => ApplicationFee)
  public applicationFees: ApplicationFee[] = [];

  /** The payment service provider (PSP) fee applied to the charge. */
  @Type(() => ApplicationFee)
  public pspFees: ApplicationFee[] = [];
}

/**
 * Details on order fulfillment.
 *
 * Orders can only be created with at most one fulfillment. However, orders returned by the API may contain multiple fulfillments.
 */
export class OrderFulfillment {
  /**
   * The status of the fulfillment.
   *
   * PROPOSED: A shipment is requested.
   * RESERVED: Fulfillment accepted. Shipment processing.
   * PREPARED: Shipment packaged. Shipping label created.
   * COMPLETED: Package has been shipped.
   * CANCELLED: Shipment has been canceled.
   * FAILED: Shipment has failed.
   */
  public status = 'PROPOSED';
  /** The type of the fulfillment, e.g. 'DINE_IN', 'PICKUP', 'SHIPMENT'. */
  public type: string;

  /** The auth-code for handing over the order. */
  public authCode: string;

  public firstname?: string;
  public lastname?: string;
  public email?: string;
  public phoneNumber?: string;
  public companyName?: string;

  public dineInPlaceId?: string;

  public deliveryAddress?: Address;
  public deliveryFloor?: string;
  public deliveryShipping: Money = new Money();

  /** temporary */
  public preferredTimeDiff?: string;
  /** The guest preferred time for pick-up or delivery, in ISO 8601 time and duration format. */
  public preferredTime?: string;

  /** The payment method the user preferred to use. */
  public preferredPaymentMethod?: OrderFulfillmentPaymentMethod;

  /** Customer's special requests. */
  public specialRequests?: string;
  /** Restaurant's notes. */
  public notes?: string;

  /** The timestamp indicating when the order was requested (set to PROPOSED), in RFC 3339 format. */
  public placedAt?: string;
  /** The timestamp indicating when the fulfillment was accepted (set to RESERVED), in RFC 3339 format. */
  public acceptedAt?: string;
  /** The timestamp indicating when the fulfillment is expected to be completed, in RFC 3339 format. */
  public expectedAt?: string;
}

/**
 * Class representing an food order.
 */
export class Order {

  public id: string;
  public eventId?: string;
  public userId?: string;
  public accountId?: string;
  public locationId: string;

  /** The Customer ID of the customer associated with the order. */
  public customerId?: string;
  /** The Reservation ID assigned to this order. */
  public reservationId?: string | null;

  /** The order receipt ID associated with the order. */
  public receiptId?: string;

  // legacy
  public pickupPreferredTime?: string;

  /** The current state of the order, see OrderStatusConstants. */
  public status: OrderStatusConstants = OrderStatusConstants.OPEN;

  /** Indicates who created this order object, e.g. 'customer', 'self'. */
  public createdBy = 'customer';

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;

  /** TThe timestamp indicating when the order was placed, in RFC 3339 format. */
  public placedAt?: string;
  /** The timestamp indicating when the order was accepted, in RFC 3339 format. */
  public acceptedAt?: string;

  /** TThe timestamp indicating when the order was completed, in RFC 3339 format. */
  public completedAt?: string;

  /** The line items included in the order. */
  @Type(() => OrderLineItem)
  public lineItems: OrderLineItem[] = [];

  /**
   * The list of all discounts associated with the order.
   *
   * Discounts can be scoped to either ORDER or LINE_ITEM. For discounts scoped to LINE_ITEM,
   * an OrderLineItemAppliedDiscount must be added to each line item that the discount applies to.
   * For discounts with ORDER scope, the server will generate an OrderLineItemAppliedDiscount for every line item.
   */
  @Type(() => OrderLineItemDiscount)
  public discounts: OrderLineItemDiscount[];

  /**
   * The list of all vouchers associated with the order.
   */
  @Type(() => OrderLineItemVoucher)
  public vouchers: OrderLineItemVoucher[];

  /**
   * The list of all taxes associated with the order.
   *
   * Taxes can be scoped to either ORDER or LINE_ITEM. For taxes with LINE_ITEM scope,
   * an OrderLineItemAppliedTax must be added to each line item that the tax applies to.
   * For taxes with ORDER scope, the server will generate an OrderLineItemAppliedTax for every line item.
   *
   * On reads, each tax in the list will include the total amount of that tax applied to the order.
   *
   * IMPORTANT: If LINE_ITEM scope is set on any taxes in this field,
   * usage of the deprecated line_items.taxes field will result in an error. Please use line_items.applied_taxes instead.
   */
  @Type(() => OrderLineItemTax)
  public taxes: OrderLineItemTax[];

  /**
   * Details on order fulfillment.
   *
   * Orders can only be created with at most one fulfillment. However, orders returned by the API may contain multiple fulfillments.
   */
  @Type(() => OrderFulfillment)
  public fulfillments: OrderFulfillment[] = [];

  /**
   * Details on order fulfillment.
   */
  @Type(() => OrderFulfillment)
  public fulfillment?: OrderFulfillment;

  /** The Tenders which were used to pay for the Order. */
  @Type(() => Tender)
  public tenders?: Tender[];

  /** The subtotal amount of money to collect for the order. */
  public subtotalMoney: Money = new Money();
  /** The total discount amount of money to collect for the order. */
  public totalDiscountMoney?: Money;
  /** The total voucher amount of money to collect for the order. */
  public totalVoucherMoney?: Money;
  /** The total tax amount of money to collect for the order. */
  public totalTaxMoney?: Money;
  /** The total tip amount of money to collect for the order. */
  public totalTipMoney: Money = new Money();
  /** The total amount of money to collect for the order. */
  public totalMoney: Money = new Money();

  public marketingConsent = false;

  /**
   * Application-defined data attached to this order. Metadata fields are intended to store descriptive references
   * or associations with an entity in another system or store brief information about the object.
   * Square does not process this field; it only stores and returns it in relevant API calls.
   * Do not use metadata to store any sensitive information (personally identifiable information, card details, etc.).
   *
   * Keys written by applications must be 60 characters or less and must be in the character set [a-zA-Z0-9_-].
   * Entries may also include metadata generated by Square. These keys are prefixed with a namespace,
   * separated from the key with a ':' character.
   *
   * Values have a max length of 255 characters.
   *
   * An application may have up to 10 entries per metadata field.
   *
   * Entries written by applications are private and can only be read or modified by the same application.
   */
  public metadata: { [key: string]: string };

  /**
   * Optional, other IDs used to associate the customer profile with an entity in other systems.
   * An array element has the format <REFERENCE_SERVICE>:<REFERENCE_ID>, i.e. 'GOOGLE_RESERVE:345675432'.
   */
  public refs?: string[];

  constructor(locationId: string) {
    this.locationId = locationId;
  }
}
