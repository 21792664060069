import { DeliveryRule, Location } from '../models/location';
import { CatalogMenu } from '../models/catalog-menu';
import { CatalogCategory } from '../models/catalog-category';
import { CatalogItem } from '../models/catalog-item';
import { CatalogItemVariation } from '../models/catalog-item-variation';
import { CatalogModifier } from '../models/catalog-modifier';
import { CatalogModifierList } from '../models/catalog-modifier-list';
import { CatalogDiscount } from '../models/catalog-discount';
import { CatalogDiscountCode } from '../models/catalog-discount-code';
import { CatalogPricingRule } from '../models/catalog-pricing-rule';
import { CatalogTax } from '../models/catalog-tax';
import { CatalogDiet } from '../models/catalog-diet';
import { CatalogAllergen } from '../models/catalog-allergen';
import { CatalogVoucher } from '../models/catalog-voucher';
import { Order, OrderFulfillmentType } from '../models/order';
import { OrderLineItem, OrderLineItemModifier } from '../models/order-line-item';
import { Money } from '../models/money';
import { DictUtils } from '../utils/dict-utils';
import { LocationPriceUtils } from '../utils/location-price-utils';
import { MoneyUtils } from '../utils/money-utils';
import { priceCalculator } from '../manager/price-calculator/price-calculator.service';
import { DiscountSet } from '../manager/price-calculator/discount-promotion-engine';
import { VoucherSet } from '../manager/price-calculator/voucher-promotion-engine';

/**
 * The class represents a shopping basket with necessary functionality.
 */
export class Basket {

  // Look-up-tables
  public catalogMenuMap: Map<string, CatalogMenu> = new Map();
  public catalogCategoryMap: Map<string, CatalogCategory> = new Map();
  public catalogCategoryMenuMap: Map<string, CatalogMenu> = new Map();
  public catalogItemMap: Map<string, CatalogItem> = new Map();
  public catalogItemVariationMap: Map<string, CatalogItemVariation> = new Map();
  public catalogModifierMap: Map<string, CatalogModifier> = new Map();
  public voucherSets: VoucherSet[] = [];
  public catalogDiscountMap: Map<string, CatalogDiscount> = new Map();
  public catalogPricingRuleMap: Map<string, CatalogPricingRule> = new Map();
  public catalogTaxMap: Map<string, CatalogTax> = new Map();

  public catalogDiets: CatalogDiet[] = [];
  public supportedCatalogDiets: string[] = [];
  public selectedCatalogDietIds: string[] = [];

  public catalogAllergens: CatalogAllergen[] = [];
  public selectedCatalogAllergenIds: string[] = [];

  private location: Location;
  private cart: Order;
  public cartTotalItems = 0;

  public variationQuantityMap: Map<string, number> = new Map();

  private reservationId: string | null = null;

  public deliveryRule?: DeliveryRule;
  public discountCode?: CatalogDiscountCode;
  public tip: Money = new Money();
  public tipOptions: { text: string, tip: Money }[] = [];

  // properties specific to the selected orderingType
  public orderingType: string;
  public orderTotalItems = 0;
  public order: Order;

  private collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

  constructor() {
  }

  public setLocation(location: Location): void {
    this.location = location;

    if (!this.location.orderingDeliveryRules || this.location.orderingDeliveryRules.length === 0) {
      // legacy
      if (this.location.orderingDeliveryArea && this.location.orderingDeliveryArea !== 'DEPRECATED') {
        this.deliveryRule = new DeliveryRule();
        this.deliveryRule.triggerType = 'POSTAL_CODE';
        this.deliveryRule.postalCodes = location.orderingDeliveryArea;
        this.deliveryRule.minOrderValue = new Money();
        this.deliveryRule.minOrderValue.amount = location.orderingDeliveryMinOrderValue.amount;
        this.deliveryRule.minOrderValue.currency = location.orderingDeliveryMinOrderValue.currency;
        this.deliveryRule.shipping = new Money();
        this.deliveryRule.shipping.amount = location.orderingDeliveryShipping.amount;
        this.deliveryRule.shipping.currency = location.orderingDeliveryShipping.currency;
      } else {
        this.deliveryRule = undefined;
      }
    }

    this.supportedCatalogDiets = location.orderingSupportedDiets ? location.orderingSupportedDiets.split(',') : [];

    this.updateOrderMeta();
  }

  public setOrderingType(orderingType: string): void {
    this.orderingType = orderingType;
    this.updateOrderMeta();
  }

  public setCatalogObjects(menus: CatalogMenu[], categoryItemMap: Map<string, CatalogItem[]>,
                           modifierMap: Map<string, { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }>,
                           catalogDiscounts: CatalogDiscount[], catalogPricingRules: CatalogPricingRule[],
                           catalogTaxes: CatalogTax[], dietMap: Map<string, CatalogDiet>, allergenMap: Map<string, CatalogAllergen>): void {
    this.catalogMenuMap.clear();
    this.catalogCategoryMap.clear();
    this.catalogItemMap.clear();
    this.catalogItemVariationMap.clear();
    this.catalogModifierMap.clear();
    this.catalogDiscountMap.clear();
    this.catalogPricingRuleMap.clear();
    this.catalogTaxMap.clear();
    this.catalogDiets.length = 0;
    this.catalogAllergens.length = 0;

    menus.forEach((menu: CatalogMenu) => {
      this.catalogMenuMap.set(menu.id, menu);
      if (menu.categories) {
        menu.categories.forEach((category: CatalogCategory) => {
          this.catalogCategoryMap.set(category.id, category);
          this.catalogCategoryMenuMap.set(category.id, menu);
        });
      }
    });
    categoryItemMap.forEach((items: CatalogItem[]) => {
      items.forEach((item: CatalogItem) => {
        this.catalogItemMap.set(item.id, item);
        item.variations.forEach((variation: CatalogItemVariation) => this.catalogItemVariationMap.set(variation.id, variation));
      });
    });
    modifierMap.forEach((modifierData: { modifierList: CatalogModifierList, modifiers: CatalogModifier[] }) => {
      modifierData.modifiers.forEach((modifier: CatalogModifier) => this.catalogModifierMap.set(modifier.id, modifier));
    });

    catalogDiscounts.forEach((catalogDiscount: CatalogDiscount) => this.catalogDiscountMap.set(catalogDiscount.id, catalogDiscount));
    catalogPricingRules.forEach((catalogPricingRule: CatalogPricingRule) => this.catalogPricingRuleMap.set(catalogPricingRule.id, catalogPricingRule));

    catalogTaxes.forEach((catalogTax: CatalogTax) => this.catalogTaxMap.set(catalogTax.id, catalogTax));

    this.catalogDiets.push(...Array.from(dietMap.values()).sort((a: CatalogDiet, b: CatalogDiet) => this.collator.compare(a.abbreviation, b.abbreviation)));

    this.catalogAllergens.push(...Array.from(allergenMap.values()).sort((a: CatalogAllergen, b: CatalogAllergen) => this.collator.compare(a.abbreviation, b.abbreviation)));

    this.updateOrderMeta();
  }

  public setReservationId(reservationId: string | undefined): void {
    this.reservationId = reservationId || null;
  }

  public setDeliveryRule(rule: DeliveryRule | undefined): void {
    this.deliveryRule = rule;
    this.updateOrderMeta();
  }

  public setDiscountCode(code: CatalogDiscountCode | undefined): void {
    this.discountCode = code;
    this.updateOrderMeta();
  }

  public setCatalogVoucher(voucher: CatalogVoucher | undefined, rule?: CatalogPricingRule): void {
    this.voucherSets.length = 0;
    if (voucher) {
      this.voucherSets.push({
        voucher,
        rule,
      });
    }
    this.updateOrderMeta();
  }

  public setTip(tip: Money): void {
    this.tip = tip;
    this.updateOrderMeta();
  }

  public resetTip(): void {
    this.tip = new Money();
    this.updateOrderMeta();
  }

  public setCart(order: Order): void {
    this.cart = order;

    // sort lineItems by CatalogItem ordinal
    this.cart.lineItems = this.cart.lineItems.sort((a: OrderLineItem, b: OrderLineItem) => {
      const ordinalA = this.catalogItemMap.has(a.itemId) && this.catalogItemMap.get(a.itemId).ordinal ? this.catalogItemMap.get(a.itemId).ordinal : 0;
      const ordinalB = this.catalogItemMap.has(b.itemId) && this.catalogItemMap.get(b.itemId).ordinal ? this.catalogItemMap.get(b.itemId).ordinal : 0;
      return ordinalA - ordinalB;
    });

    this.updateOrderMeta();
  }

  public getCart(): Order {
    return this.cart;
  }

  public exportOrder(): Order {
    return JSON.parse(JSON.stringify(this.order)) as Order;
  }

  private updateOrderMeta(): void {
    if (this.location && this.cart) {
      this.updateLineItemData(
        this.cart.lineItems, this.catalogCategoryMap, this.catalogCategoryMenuMap,
        this.catalogItemMap, this.catalogItemVariationMap, this.catalogModifierMap, this.location
      );

      const order: Order = JSON.parse(JSON.stringify(this.cart)) as Order;
      order.reservationId = this.reservationId;

      order.lineItems = order.lineItems
        .filter((lineItem: OrderLineItem) => lineItem.availableFulfillmentTypes?.includes(this.orderingType as OrderFulfillmentType));

      const discountSets: DiscountSet[] = [];
      this.catalogDiscountMap.forEach((discount: CatalogDiscount) => {
        const pricingRule = this.catalogPricingRuleMap.get(discount.pricingRuleId);
        if (pricingRule) {
          discountSets.push({
            discount,
            rule: pricingRule,
            code: this.discountCode?.pricingRuleId === discount.pricingRuleId ? this.discountCode : undefined,
          });
        }
      });

      priceCalculator.calculate({
        order,
        tip: this.tip,
        orderingType: this.orderingType as OrderFulfillmentType,
        catalogCategoryMenuMap: this.catalogCategoryMenuMap,
        catalogItemMap: this.catalogItemMap,
        discountSets,
        voucherSets: this.voucherSets,
        catalogTaxMap: this.catalogTaxMap,
        shippingMoney: this.orderingType === 'SHIPMENT' && this.deliveryRule ? this.deliveryRule.shipping : undefined,
      });

      this.order = order;

      this.orderTotalItems = 0;
      order.lineItems.forEach((lineItem) => {
        this.orderTotalItems += lineItem.quantity;
      });

      this.cartTotalItems = 0;
      this.variationQuantityMap.clear();
      this.cart.lineItems.forEach((lineItem) => {
        this.cartTotalItems += lineItem.quantity;
        if (this.variationQuantityMap.has(lineItem.itemVariationId)) {
          this.variationQuantityMap.set(lineItem.itemVariationId, this.variationQuantityMap.get(lineItem.itemVariationId) + lineItem.quantity);
        } else {
          this.variationQuantityMap.set(lineItem.itemVariationId, lineItem.quantity);
        }
      });

      this.updateTipOptions(order.totalMoney);
    }
  }

  private updateLineItemData(orderLineItems: OrderLineItem[],
                             catalogCategoryMap: Map<string, CatalogCategory>, catalogCategoryMenuMap: Map<string, CatalogMenu>,
                             catalogItemMap: Map<string, CatalogItem>, catalogItemVariationMap: Map<string, CatalogItemVariation>,
                             catalogModifierMap: Map<string, CatalogModifier>,
                             location: Location): void {
    orderLineItems.forEach((lineItem: OrderLineItem) => {
      const item: CatalogItem | undefined = catalogItemMap.get(lineItem.itemId);
      const variation: CatalogItemVariation | undefined = catalogItemVariationMap.get(lineItem.itemVariationId);

      // update item and variation data in OrderLineItem
      if (item && item.visibility !== 'hidden' && variation) {
        const category: CatalogCategory | undefined = catalogCategoryMap.get(item.categoryId);
        const menu: CatalogMenu | undefined = catalogCategoryMenuMap.get(item.categoryId);

        if (menu && menu.visibility !== 'hidden' && category && category.visibility !== 'hidden') {
          lineItem.name = item.name;
          const variationName: string | null | undefined = DictUtils.getString(item.strings, variation.name, item.defaultLang);
          lineItem.variationName = variationName ? variationName : '';
          lineItem.priceMoney = LocationPriceUtils.getPrice(variation, location.id);  // TODO DEPRECATED
          lineItem.basePriceMoney = LocationPriceUtils.getPrice(variation, location.id);

          if (lineItem.basePriceMoney) {
            lineItem.variationTotalPriceMoney = MoneyUtils.multScalar(lineItem.quantity, lineItem.basePriceMoney);

            lineItem.modifierTotalPriceMoney = new Money();
            if (lineItem.modifiers) {
              const newModifiers: OrderLineItemModifier[] = [];
              lineItem.modifiers.forEach((lineItemModifier: OrderLineItemModifier) => {
                const catalogModifier: CatalogModifier | undefined = catalogModifierMap.get(lineItemModifier.modifierId);
                if (catalogModifier) {
                  lineItemModifier.name = DictUtils.getString(catalogModifier.strings, catalogModifier.name, catalogModifier.defaultLang);
                  lineItemModifier.basePriceMoney = catalogModifier.priceMoney;
                  lineItemModifier.totalPriceMoney = MoneyUtils.multScalar(lineItem.quantity, lineItemModifier.basePriceMoney);
                  newModifiers.push(lineItemModifier);

                  lineItem.modifierTotalPriceMoney = MoneyUtils.add(lineItemModifier.totalPriceMoney, lineItem.modifierTotalPriceMoney);
                }
              });
              lineItem.modifiers = newModifiers;
            }

            lineItem.grossSalesMoney = MoneyUtils.add(lineItem.variationTotalPriceMoney, lineItem.modifierTotalPriceMoney);
            lineItem.totalMoney = MoneyUtils.multScalar(1, lineItem.grossSalesMoney);

            lineItem.availableFulfillmentTypes = [];
            if (item.availableForDineIn !== false) {
              lineItem.availableFulfillmentTypes.push(OrderFulfillmentType.DINE_IN);
            }
            if (item.availableForPickup !== false) {
              lineItem.availableFulfillmentTypes.push(OrderFulfillmentType.PICKUP);
            }
            if (item.availableForDelivery !== false) {
              lineItem.availableFulfillmentTypes.push(OrderFulfillmentType.SHIPMENT);
            }
          }
        }
      }
    });
  }

  /**
   * Smart tip amounts,
   * see https://squareup.com/help/us/en/article/5069-accept-tips-with-the-square-app
   *
   * @param orderTotal the order total
   */
  private updateTipOptions(orderTotal: Money): void {
    this.tipOptions.length = 0;

    this.tipOptions.push({ text: 'BASKET_DETAILS.TIP_NO', tip: new Money() });
    if (orderTotal.amount <= 10) {
      this.tipOptions.push({ text: '0,50 €', tip: new Money(0.5) });
      this.tipOptions.push({ text: '1,00 €', tip: new Money(1) });
      this.tipOptions.push({ text: '1,50 €', tip: new Money(1.5) });
    } else {
      this.tipOptions.push({ text: '5 %', tip: MoneyUtils.multScalar(0.05, orderTotal) });
      this.tipOptions.push({ text: '10 %', tip: MoneyUtils.multScalar(0.1, orderTotal) });
      this.tipOptions.push({ text: '15 %', tip: MoneyUtils.multScalar(0.15, orderTotal) });
    }

    if (this.tipOptions.find((tipOption: { text: string, tip: Money }) => tipOption.tip.amount === this.tip.amount) === undefined
      || !(this.location.orderingUseTips && this.location.orderingUseTips.split(',').includes(this.orderingType))) {
      this.tip = new Money();
    }
  }
}
