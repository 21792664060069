import { Money } from './money';
import { LocalizedCatalogObject } from './catalog-object';

/**
 * Identifies the general functional type of the voucher 
 * (e.g., gift card, store credit, promotional coupon, etc.).
 *
 * - GIFT_CARD: Typically represents a prepaid card that can be applied 
 *   toward purchases, functioning as a payment method.
 * - STORE_CREDIT: Often issued as compensation or return value, 
 *   also functioning as a payment method.
 * - PROMO_VOUCHER: Might behave more like a discount (e.g., 10 USD off),
 *   depending on your business logic.
 */
export enum CatalogVoucherType {
  GIFT_CARD = 'GIFT_CARD',
  STORE_CREDIT = 'STORE_CREDIT',
  PROMO_VOUCHER = 'PROMO_VOUCHER',
}

/**
 * Indicates the purpose type of a voucher with respect to taxation:
 * - SINGLE_PURPOSE: The taxable status (e.g., the applicable tax rate)
 *   is already determined at the time of issuing the voucher (e.g., 
 *   valid only for products taxed at 19% in one specific country).
 * - MULTI_PURPOSE: The taxable details are only established at redemption
 *   because the voucher can be used for multiple product categories or 
 *   tax rates.
 *
 * Note: This classification is important for deciding when and how tax 
 * is accounted for (at issuance vs. at redemption).
 */
export enum CatalogVoucherPurposeType {
  SINGLE_PURPOSE = 'SINGLE_PURPOSE',
  MULTI_PURPOSE = 'MULTI_PURPOSE'
}

/**
 * Represents the behavior type of a voucher in the context of a transaction:
 * - PAYMENT_METHOD: The voucher can be used as a payment method
 *  (e.g., gift card, store credit).
 * - DISCOUNT: The voucher is applied as a discount to the total amount
 *  (e.g., promotional coupon).
 */
export enum VoucherBehaviorType {
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  DISCOUNT = 'DISCOUNT'
}

/**
 * Represents the possible states of a voucher in its lifecycle:
 * - ACTIVE: The voucher is valid and can be redeemed. This could also 
 *   cover partial redemptions if the voucher still has a remaining balance.
 * - PARTIALLY_REDEEMED: (optional) If you want to explicitly separate 
 *   vouchers that have some portion already used, but are not fully redeemed.
 * - REDEEMED: The voucher has been fully redeemed (no balance left or 
 *   usage limit reached).
 * - EXPIRED: The voucher has passed its validUntilDate, making it unusable.
 * - INACTIVE: The voucher exists but is currently not redeemable, 
 *   possibly due to admin settings, etc.
 */
export enum CatalogVoucherStatus {
  ACTIVE = 'ACTIVE',
  PARTIALLY_REDEEMED = 'PARTIALLY_REDEEMED',
  REDEEMED = 'REDEEMED',
  EXPIRED = 'EXPIRED',
  INACTIVE = 'INACTIVE',
}

/**
 * A Voucher (e.g., gift card or store credit) within the catalog/checkout system.
 * This interface can be adjusted to match your specific business needs.
 */
export interface CatalogVoucher extends LocalizedCatalogObject {
  /**
   * The unique ID of the account that owns this voucher object.
   */
  accountId: string;

  /**
   * The ID of the pricing rule that created the discount.
   * Optional: Set this if the voucher's behavior is similar to a discount.
   */
  pricingRuleId?: string | null;

  /**
   * The user-defined name of the voucher (e.g., "Holiday Gift Card").
   */
  name: string;

  /**
   * Indicates the general category of the voucher (e.g., gift card, store credit).
   */
  voucherType: CatalogVoucherType;

  /**
   * Determines whether the voucher is considered a single-purpose 
   * or multi-purpose voucher for tax purposes.
   */
  voucherPurposeType: CatalogVoucherPurposeType;

  /**
   * Describes the behavior of the voucher in the context of a transaction.
   */
  behavior: VoucherBehaviorType;

  /**
   * A code that the customer must enter to redeem the voucher.
   */
  redemptionCode: string;

  /**
   * The initial total value of the voucher (e.g., 50 USD).
   */
  initialValueMoney: Money;

  /**
   * The current remaining value of the voucher. This is relevant 
   * when partial redemptions are allowed. If it's a single-use voucher,
   * you can omit this or set it to zero after it's used.
   */
  currentBalanceMoney: Money;

  /**
   * The current status of the voucher (e.g., ACTIVE, REDEEMED, EXPIRED).
   * Some implementations prefer a separate state (e.g., PARTIALLY_REDEEMED)
   * for when the balance is not fully used up but has been partially applied.
   */
  status: CatalogVoucherStatus;

  /**
   * The maximum number of times this voucher can be redeemed.
   * If it's a single-use voucher, set usageLimit = 1.
   * If it can be redeemed multiple times until balance is depleted,
   * you can omit this or use a high number.
   */
  usageLimit?: number | null;

  /**
   * The number of times this voucher has already been redeemed.
   * This can help determine if further redemptions are allowed.
   */
  usageCount: number;

  /**
   * Indicates whether this voucher is present at all locations
   * (including future ones), unless explicitly excluded.
   */
  presentAtAllLocations?: boolean | null;

  /**
   * A list of location IDs where this voucher is explicitly available.
   */
  presentAtLocationIds?: string[] | null;

  /**
   * A list of location IDs where this voucher is explicitly unavailable,
   * even if presentAtAllLocations is true.
   */
  absentAtLocationIds?: string[] | null;
}
