import { CatalogItem } from '../../models/catalog-item';
import { CatalogMenu } from '../../models/catalog-menu';
import { CatalogTax } from '../../models/catalog-tax';
import { Order, OrderFulfillmentType } from '../../models/order';
import { Money } from '../../models/money';
import { DiscountSet } from './discount-promotion-engine';
import { taxEngine } from './tax-engine';
import { VoucherSet } from './voucher-promotion-engine';
import { MoneyUtils } from '../../utils/money-utils';
import { promotionEngine } from './promotion-engine';


export interface PriceCalculatorOptions {
	order: Order;
	tip: Money;
	orderingType: OrderFulfillmentType;
	shippingMoney?: Money;
  discountSets: DiscountSet[];
  voucherSets: VoucherSet[];
	catalogCategoryMenuMap: Map<string, CatalogMenu>;
	catalogItemMap: Map<string, CatalogItem>;
	catalogTaxMap: Map<string, CatalogTax>;
}

class PriceCalculator {

  public calculate({
    order,
    tip,
    orderingType,
    shippingMoney,
    discountSets,
    voucherSets,
    catalogCategoryMenuMap,
    catalogItemMap,
    catalogTaxMap,
  }: PriceCalculatorOptions): void {
    promotionEngine.checkAndApplyPromotions({
      order,
      discountSets,
      voucherSets,
      orderingType,
      catalogCategoryMenuMap,
      catalogItemMap
    });
    this.updateTotal(order, tip, shippingMoney);
    taxEngine.checkAndApplyTaxes({
      order,
      catalogTaxMap,
      orderingType,
      catalogItemMap
    });
    this.updateTotal(order, tip, shippingMoney);
  }

  private updateTotal(order: Order, tip: Money, shipping?: Money): void {
    order.subtotalMoney = new Money();
    order.totalMoney = new Money();

    if (order) {
      order.lineItems.forEach((lineItem) => {
        order.subtotalMoney = MoneyUtils.add(lineItem.grossSalesMoney, order.subtotalMoney);
      });

      order.totalMoney = MoneyUtils.multScalar(1, order.subtotalMoney);

      if (order.totalDiscountMoney) {
        order.totalMoney = MoneyUtils.subtract(order.totalMoney, order.totalDiscountMoney);
      }
      if (order.totalVoucherMoney) {
        order.totalMoney = MoneyUtils.subtract(order.totalMoney, order.totalVoucherMoney);
      }

      if (shipping) {
        order.totalMoney = MoneyUtils.add(shipping, order.totalMoney);
      }

      order.totalTipMoney = MoneyUtils.multScalar(1, tip);
      order.totalMoney = MoneyUtils.add(tip, order.totalMoney);
    }
  }
}

export const priceCalculator = new PriceCalculator();
