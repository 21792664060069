import { CatalogItem } from "../../models/catalog-item";
import { CatalogMenu } from "../../models/catalog-menu";
import { Order, OrderFulfillmentType } from "../../models/order";
import { discountEngine, DiscountSet } from "./discount-promotion-engine";
import { voucherEngine, VoucherSet } from "./voucher-promotion-engine";


export interface PromotionEngineOptions {
	order: Order;
	discountSets: DiscountSet[];
	voucherSets: VoucherSet[];
	orderingType: OrderFulfillmentType;
	catalogCategoryMenuMap: Map<string, CatalogMenu>;
	catalogItemMap: Map<string, CatalogItem>;
}

class PromotionEngine {
  /**
   * Determines the promotions.
   */
  public checkAndApplyPromotions({
    order,
    discountSets,
    voucherSets,
    orderingType,
    catalogCategoryMenuMap,
    catalogItemMap,
  }: PromotionEngineOptions): { eligibleDiscountSets: DiscountSet[]; eligibleVoucherSets: VoucherSet[] } {
    const eligibleDiscountSets = discountEngine.checkAndApplyDiscounts({
      order,
      discountSets,
      orderingType,
      catalogCategoryMenuMap,
      catalogItemMap,
    });
    const eligibleVoucherSets = voucherEngine.checkAndApplyVouchers({
      order,
      voucherSets,
      orderingType,
      catalogCategoryMenuMap,
      catalogItemMap,
    });

    return { eligibleDiscountSets, eligibleVoucherSets };
  }
}

export const promotionEngine = new PromotionEngine();
