import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { Location } from '../models/location';
import { LocationImage } from '../models/location-image';
import { LocationPlace } from '../models/location-place';
import { LocationAccess } from '../access/location-access.service';
import { ReservationSettingsManager } from './reservation-settings-manager.service';
import { OrderSettingsManager } from './order-settings-manager.service';
import { ReservationSettingsAdapter } from './reservation-settings.adapter';
import { OrderSettingsAdapter } from './order-settings.adapter';

/**
 * Class providing management methods for locations.
 */
@Injectable({
  providedIn: 'root'
})
export class LocationManager {

  /**
   * The default constructor.
   */
  constructor(
    private locationAccess: LocationAccess,
    private reservationSettingsManager: ReservationSettingsManager,
    private orderSettingsManager: OrderSettingsManager
  ) {
  }

  /**
   * Returns the Locations specified by the IDs.
   *
   * @param locationIds the Location IDs
   * @returns the found locations, otherwise empty list
   */
  public getLocations(locationIds: string[]): Observable<Location[]> {
    return this.locationAccess.getLocations(locationIds).pipe(
      switchMap(async (locations: Location[]) => {
        return await this.applySettingsToLocations(locations);
      })
    );
  }

  /**
   * Returns the Location specified by the ID.
   *
   * @param locationId the Location ID
   * @returns the found location, otherwise undefined
   */
  public getLocation(locationId: string): Observable<Location | undefined> {
    return this.locationAccess.getLocation(locationId).pipe(
      switchMap(async (location: Location | undefined) => {
        if (location) {
          return await this.applySettingsToLocation(location);
        }
        return location;
      })
    );
  }

  /**
   * Returns the Location specified by the link name.
   *
   * @param locationLinkName the Location link name
   * @returns the found location, otherwise undefined
   */
  public getLocationByLinkName(locationLinkName: string): Observable<Location | undefined> {
    return this.locationAccess.getLocationByLinkName(locationLinkName).pipe(
      switchMap(async (location: Location | undefined) => {
        if (location) {
          return await this.applySettingsToLocation(location);
        }
        return location;
      })
    );
  }

  /**
   * Returns the LocationImage specified by the ID.
   *
   * @param locationImageId the LocationImage ID
   * @returns the found locationImage, otherwise undefined
   */
  public getLocationImage(locationImageId: string): Observable<LocationImage | undefined> {
    return this.locationAccess.getLocationImage(locationImageId);
  }

  /**
   * Returns all LocationPlaces of a location.
   *
   * @param locationId the ID of the location
   * @returns the found LocationPlaces, otherwise empty list
   */
  public getAllLocationPlaces(locationId: string): Observable<LocationPlace[]> {
    return this.locationAccess.getAllLocationPlaces(locationId);
  }

  /**
   * Returns the LocationPlace specified by the ID.
   *
   * @param locationPlaceId the LocationPlace ID
   * @returns the found LocationPlace, otherwise undefined
   */
  public getLocationPlace(locationPlaceId: string): Observable<LocationPlace | undefined> {
    return this.locationAccess.getLocationPlace(locationPlaceId);
  }

  /**
   * Applies reservation and order settings to an array of locations
   *
   * @param locations The locations to update
   * @returns The updated locations
   */
  private async applySettingsToLocations(locations: Location[]): Promise<Location[]> {
    const updatedLocations: Location[] = [];

    for (const location of locations) {
      const updatedLocation = await this.applySettingsToLocation(location);
      updatedLocations.push(updatedLocation);
    }

    return updatedLocations;
  }

  /**
   * Applies reservation and order settings to a single location
   *
   * @param location The location to update
   * @returns The updated location
   */
  private async applySettingsToLocation(location: Location): Promise<Location> {
    try {
      const reservationSettings = await this.reservationSettingsManager.getReservationSettings(location.id)
        .pipe(first()).toPromise();

      ReservationSettingsAdapter.mapReservationSettingsToLocation(location, reservationSettings);

      const orderSettings = await this.orderSettingsManager.getOrderSettings(location.id)
        .pipe(first()).toPromise();

      OrderSettingsAdapter.mapOrderSettingsToLocation(location, orderSettings);
    } catch (error) {
      console.error('Error applying settings to location:', error);
    }

    return location;
  }
}
